import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SnackbarAlert from "../../components/SnackbarAlert";
import styles from "./addProduct.module.css";
import axios from "axios";
import config from "../../config";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { TextField, IconButton, Popover } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomLink from "../../components/CustomLink";

import { createTheme, ThemeProvider } from "@mui/material";

export default function AddProduct(props) {
  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  }, []);

  const { state } = useLocation();

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const [editable, setEditable] = useState(false);

  const [images, setImages] = useState(null);

  const [loading, setLoading] = useState(true);

  const [headings, setHeadings] = useState([]);

  const [productName, setProductName] = useState("");
  const [selectedHeadings, setSelectedHeadings] = useState([]);
  const [productCode, setProductCode] = useState("");
  const [productType, setProductType] = useState("");
  const [desc, setDesc] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("Template1");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [text, setText] = useState([]);

  const [editProductId, setEditProductId] = useState("");

  const [customTemplatesData, setCustomTemplatesData] = useState([]);

  const [productCategories, setProductCategories] = useState([]);

  const [Templates, setTemplates] = useState([
    "Template1",
    "Template2",
    "Template3",
    "Template4",
    "Template5",
    "Template6",
    "Template7",
  ]);

  const defaults = {
    Template1: [10, 20],
    Template2: [10, 20],
    Template3: [17, 23],
    Template4: [20, 24],
    Template5: [23, 30],
    Template6: [15, 33],
    Template7: [15, 33],
  };

  useEffect(() => {
    props.setHeader(() => (
      <Typography variant="h5" noWrap component="div">
        {state ? "Edit Product" : "New Product"}
      </Typography>
    ));
    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  useEffect(() => {
    if (state) {
      setEditable(true);
      const product = state.product;
      setProductName(product.productName);
      setSelectedCategory(product.productCategory);
      setProductCode(product.productCode);
      setSelectedTemplate(product.template);
      setSelectedImage(product.imageName);
      setSelectedHeadings(product.headings);
      setText(product.text);
      setDesc(product.desc);
      setEditProductId(product._id);
    }
  }, []);

  const fetchImages = async () => {
    const url = config.uri + "admin/" + config.version + "/imageNameList";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setImages(e.data);
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const fetchHeadings = async () => {
    const url = config.uri + "admin/" + config.version + "/headings";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setHeadings(e.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarMsg("Error in fetching headings");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const fetchProductCategories = async () => {
    const url = config.uri + "admin/" + config.version + "/product_category";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setProductCategories(e.data);
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  const fetchProductType = async () => {
    const category = selectedCategory;
    const url =
      config.uri + "admin/" + config.version + "/single_product_category";

    try {
      let productCategoryValues = await axios.get(url, {
        params: { productCategory: category, company: props.company },
      });
      setProductType(productCategoryValues.data.productType);
    } catch (error) {}
  };

  const fetchTemplates = async () => {
    const url = config.uri + "admin/" + config.version + "/templates";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        for (let i = 0; i < e.data.length; i++) {
          setTemplates((prev) => [...prev, e.data[i].name]);
        }
        setCustomTemplatesData(e.data);
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    (async function () {
      await fetchProductCategories();
      await fetchHeadings();
      await fetchImages();
      await fetchTemplates();
    })();
  }, []);

  useEffect(() => {
    fetchProductType();
  }, [selectedCategory]);

  useEffect(() => {
    if (
      selectedTemplate == "Template1" ||
      selectedTemplate == "Template2" ||
      selectedTemplate == "Template3" ||
      selectedTemplate == "Template4" ||
      selectedTemplate == "Template5" ||
      selectedTemplate == "Template6" ||
      selectedTemplate == "Template7"
    ) {
      fetchHeadings();
    } else {
      let tempHeadings = [];
      for (let i = 0; i < customTemplatesData.length; i++) {
        if (selectedTemplate == customTemplatesData[i].name) {
          for (let k = 0; k < customTemplatesData[i].headings.length; k++) {
            let tempObj = { name: customTemplatesData[i].headings[k] };
            tempHeadings.push(tempObj);
          }
        }
      }
      setHeadings(tempHeadings);
    }
  }, [selectedTemplate]);

  const handleAddInput = () => {
    setSelectedHeadings([...selectedHeadings, ""]);
  };

  const handleSave = async () => {
    if (!selectedCategory) {
      return showError("product category");
    }

    if (!productType) {
      return showError("product type");
    }

    if (!selectedTemplate) {
      return showError("template");
    }

    if (!productCode) {
      return showError("product code");
    }

    if (!selectedImage) {
      return showError("product image");
    }

    if (!productName || productName.trim() == "")
      return showError("product name");

    if (text.length != selectedHeadings.length) {
      setSnackbarMsg("Error in selecting headings or text.");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    for (let i = 0; i < text.length; i++) {
      if (!text[i] || !selectedHeadings[i]) {
        setSnackbarMsg("Error in selecting headings or text.");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        return;
      }
    }

    const url = config.uri + "admin/" + config.version + "/product";

    const params = {
      category: selectedCategory,
      headings: selectedHeadings,
      text: text,
      name: productName,
      code: productCode,
      imageName: selectedImage,
      template: selectedTemplate,
      productType: productType,
      desc: desc,
      id: "",
      company: props.company,
    };

    if (editable) {
      params.id = editProductId;

      const editURL = config.uri + "admin/" + config.version + "/editProduct";

      await axios
        .post(editURL, params)
        .then((e) => {
          setSnackbarMsg("Successfully edited this product!");
          setSnackbarVariant("success");
          setSnackbarOpen(true);
          document.getElementById("close").click();
        })
        .catch((err) => {
          setSnackbarMsg("Unable to edit product due to server error!");
          setSnackbarVariant("error");
          setSnackbarOpen(true);
          return;
        });
    } else {
      await axios
        .post(url, params)
        .then((e) => {
          setSnackbarMsg("Successfully created a new product!");
          setSnackbarVariant("success");
          setSnackbarOpen(true);
          document.getElementById("close").click();
        })
        .catch((err) => {
          setSnackbarMsg(
            err.response.data || "Unable to create product due to server error!"
          );
          setSnackbarVariant("error");
          setSnackbarOpen(true);
          return;
        });
    }
  };

  const showError = (reason) => {
    setSnackbarMsg("Error: Enter Valid " + reason);
    setSnackbarVariant("error");
    setSnackbarOpen(true);
    return false;
  };

  const handleDeleteInput = (i) => {
    const newHeadings = [...selectedHeadings];
    const newText = [...text];

    newHeadings.splice(i, 1);
    newText.splice(i, 1);

    setSelectedHeadings(newHeadings);
    setText(newText);
  };

  const handleTextFieldChange = (event, index) => {
    let newArray = [...text];
    newArray[index] = event.target.value;
    setText(newArray);
  };

  const handleAddHeadings = (e, i) => {
    // if (text.length > defaults.Template1[1]) {
    //   setSnackbarMsg(
    //     "You can not add more than 20 headings for this template."
    //   );
    //   setSnackbarVariant("error");
    //   setSnackbarOpen(true);
    //   return;
    // }
    let newArray = [...selectedHeadings];
    newArray[i] = e.target.value;
    setSelectedHeadings(newArray);
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#1ca0c4",
              },
              "&:hover fieldset": {
                borderColor: "#1ca0c4",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#1ca0c4",
              },
            },
            "& .MuiInputLabel-root": {
              color: "black",
              "&.Mui-focused": {
                color: "#1ca0c4",
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "&.MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#1ca0c4",
              },
              "&:hover fieldset": {
                borderColor: "#1ca0c4",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#1ca0c4",
              },
            },
            "& .MuiInputLabel-root": {
              color: "black",
              "&.Mui-focused": {
                color: "#1ca0c4",
              },
            },
          },
        },
      },
    },
  });

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress style={{ color: "#1CA0C4" }} />
        </div>
      ) : (
        <ThemeProvider theme={theme}>
          <div className={styles.main}>
            <div className={styles.requiredContainer}>
              <FormControl>
                <TextField
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                  label="Enter Product Name"
                  variant="outlined"
                />
              </FormControl>
              <FormControl>
                <InputLabel id="selectProductCategory">
                  Product Category
                </InputLabel>
                <Select
                  MenuProps={{ style: { maxHeight: 400 } }}
                  labelId="selectProductCategory"
                  value={selectedCategory}
                  label="Product Category"
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Select a category</em>
                  </MenuItem>
                  {productCategories &&
                    productCategories.map((x, i) => (
                      <MenuItem
                        key={x.productCategory + i}
                        value={x.productCategory}
                      >
                        {x.productCategory}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl>
                <TextField
                  value={productType}
                  label="Product Type"
                  variant="outlined"
                  disabled
                />
              </FormControl>
              <FormControl>
                <TextField
                  onChange={(e) => setProductCode(e.target.value)}
                  value={productCode}
                  label="Product Code"
                  variant="outlined"
                />
              </FormControl>
              <FormControl>
                <InputLabel id="selectProductImage">Product Image</InputLabel>
                <Select
                  MenuProps={{ style: { maxHeight: 400 } }}
                  labelId="selectProductImage"
                  value={selectedImage}
                  label="Product Image"
                  onChange={(e) => setSelectedImage(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Select an image</em>
                  </MenuItem>
                  {images &&
                    images.map((x, i) => (
                      <MenuItem key={x + i} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="selectTemplate">Product Template</InputLabel>
                <Select
                  MenuProps={{ style: { maxHeight: 400 } }}
                  labelId="selectTemplate"
                  value={selectedTemplate}
                  label="Product Template"
                  onChange={(e) => setSelectedTemplate(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Select a template</em>
                  </MenuItem>
                  {Templates.map((x, i) => (
                    <MenuItem key={x + i} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                label="Product Description (max - 200 characters)"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                style={{ textAlign: "left" }}
                multiline
                rows={2}
                inputProps={{ maxLength: 200 }}
              />
            </FormControl>

            <div
              style={{
                borderBottom: "1px dotted #1ca0c4",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            />

            {selectedHeadings.map((x, i) => (
              <div
                key={i + 244}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <FormControl style={{ width: "47%" }}>
                  <InputLabel id="selected-Heading">Heading {i + 1}</InputLabel>
                  <Select
                    labelId="selected-Heading"
                    label={"Heading " + (i + 1)}
                    className="headings-target"
                    onChange={(e) => handleAddHeadings(e, i)}
                    value={selectedHeadings[i]}
                    MenuProps={{ style: { maxHeight: 400 } }}
                  >
                    <MenuItem value="">
                      <em>Select a heading</em>
                    </MenuItem>
                    {headings &&
                      headings.map((x, i) => (
                        <MenuItem key={x.name + i} value={x.name}>
                          {x.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <TextField
                  onChange={(event) => handleTextFieldChange(event, i)}
                  value={text[i]}
                  style={{ width: "47%" }}
                  label="Enter Heading Value"
                  variant="outlined"
                />
                <IconButton>
                  <DeleteIcon
                    onClick={() => handleDeleteInput(i)}
                    fontSize="large"
                  />
                </IconButton>
              </div>
            ))}

            <div
              style={{
                display: "flex",
                flex: 1,
                marginTop: "20px",
                gap: "30px",
                justifyContent: "flex-start",
              }}
            >
              <div className={styles.btn} onClick={handleSave}>
                <b>Save Product</b>
              </div>
              <div className={styles.btn} onClick={handleAddInput}>
                <b>Add Heading</b>
              </div>
              <CustomLink to="/home/products">
                <div id="close" className={styles.btn2}>
                  <b>Cancel</b>
                </div>
              </CustomLink>
            </div>
          </div>
        </ThemeProvider>
      )}
      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
    </div>
  );
}
