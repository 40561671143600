import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const DeleteAlert = ({ open, onClose, onDelete }) => {
  const handleProceed = () => {
    onDelete();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontSize: "24px", fontWeight: "bold" }}>
        Confirm Deletion
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0px",
          }}
        >
          <img src={require("../Assets/bell.png")} width={80} height={70} />
        </div>

        <DialogContentText sx={{ fontSize: "22px", fontWeight: "bold" }}>
          Are you sure you want to proceed with the deletion? This action cannot
          be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 20px",
        }}
      >
        <Button
          onClick={handleProceed}
          sx={{
            backgroundColor: "#1ca0c4",
            color: "white",
            "&:hover": {
              backgroundColor: "#147791",
            },
          }}
        >
          Submit
        </Button>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: "black",
            color: "white",
            "&:hover": {
              backgroundColor: "black",
            },
          }}
          color="error"
        >
          Cancel
        </Button>
      </DialogActions>
      <div
        style={{
          display: "flex",
          height: "45px",
          backgroundColor: "#1ca0c4",
        }}
      ></div>
    </Dialog>
  );
};

export default DeleteAlert;
