import React from "react";

import { Link } from "react-router-dom";

export default function CustomLink(props) {
  return (
    <Link
      to={props.to}
      style={{
        textDecoration: "none",
        color: "inherit",
        cursor: "pointer",
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        outline: "none",
      }}
    >
      {props.children}
    </Link>
  );
}
