import React from "react";

export default function NotFound() {
  const error = require("../Assets/error.png");

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1
        style={{
          position: "absolute",
          top: 290,
          left: 650,
          color: "#1ca0c4",
          fontSize: "62px",
        }}
      >
        OOPS
      </h1>
      <h1
        style={{
          position: "absolute",
          top: 500,
          left: 1170,
          color: "#1ca0c4",
          fontSize: "62px",
        }}
      >
        ERROR
      </h1>
      <img src={error} width={600} height={600} />
    </div>
  );
}
