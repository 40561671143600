import * as React from "react";
import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";

import config from "../config";
import axios from "axios";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import HouseIcon from "@mui/icons-material/House";
import SettingsIcon from "@mui/icons-material/Settings";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

import CustomLink from "./CustomLink";

const drawerWidth = 260;

export default function PermanentDrawerLeft(props) {
  const drawerBG = require("../Assets/drawerBackgroundImg.png");
  const dataIcon = require("../Assets/dataIcon.png");
  const templatesIcon = require("../Assets/templatesIcon.png");
  const categoryIcon = require("../Assets/categoryIcon.png");
  const typeIcon = require("../Assets/typeIcon.png");

  const [open, setOpen] = useState(false);

  const handleToggle = (props) => {
    setOpen(!open);
  };

  const [daysElapsed, setDaysElapsed] = useState(0);

  useEffect(() => {
    const url = config.uri + "public/" + config.version + "/companyDetails";
    axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        const createdOnDate = new Date(e.data?.createdOn);
        const currentDate = new Date();
        const timeDifference = currentDate - createdOnDate;
        const daysElapsed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        setDaysElapsed(daysElapsed);
      })
      .catch((err) => {});
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: "#1CA0C4",
          height: "72px",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Toolbar>
          {props.child ? (
            props.child
          ) : (
            <Typography variant="h5" noWrap component="div">
              Data Sheets
            </Typography>
          )}

          {props.printable && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
              }}
            >
              <div style={{ margin: "5px 5px 0px 50px", color: "#ACABAB" }}>
                <LocalPrintshopIcon fontSize="medium" />
              </div>
              <Typography
                variant="h6"
                noWrap
                component="div"
                color={"#ACABAB"}
                style={{ marginTop: "2px" }}
              >
                Print
              </Typography>
            </div>
          )}
        </Toolbar>
        {props.info}
        <Toolbar>
          <div
            style={{
              height: "55px",
              width: "65px",
              borderRadius: "50%",
              border: "1px solid #d9d9d9",
              marginRight: "10px",
              backgroundColor: "#d9d9d9",
              boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#1ca0c4",
                border: "1px solid #1ca0c4",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
                width: "47px",
                borderRadius: "50%",
                fontSize: "13px",
                lineHeight: "13px",
                textAlign: "center",
              }}
            >
              <b>{daysElapsed} Days</b>
            </div>
          </div>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {props.company.split(" ")[0] || ""}
            <div
              style={{
                fontSize: "15px",
                textAlign: "center",
                border: "1px solid white",
                borderRadius: "12px",
              }}
            >
              Trial
            </div>
          </Typography>
          <div
            onClick={() => props.setToken(null)}
            style={{
              backgroundColor: "white",
              color: "black",
              width: "100px",
              height: "35px",
              margin: "0 20px",
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "17px",
              cursor: "pointer",
            }}
          >
            Log Out
          </div>
          <Stack direction="row" spacing={2}>
            <Avatar sx={{ bgcolor: "gray" }}>
              {props.name[0]?.toUpperCase()}
            </Avatar>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            backgroundColor: "#71797e",
            zIndex: -1,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flex: 1,
              paddingTop: "30px",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={require("../Assets/caseWellLogo.png")}
              width="185px"
              height="75px"
            />
            <div
              style={{
                marginTop: "40px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "14px",
              }}
            >
              <CustomLink to="/home">
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    width: "220px",
                    paddingLeft: "5px",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    flexDirection: "row",
                  }}
                >
                  <h2
                    style={{
                      fontVariant: "bold",
                      lineHeight: 0,
                      fontSize: "21px",
                      color: "white",
                    }}
                  >
                    Home
                  </h2>
                  <HouseIcon fontSize="large" style={{ color: "#1ca0c4" }} />
                </div>
              </CustomLink>

              {props.roleLevel < 3 && (
                <Collapse
                  in={true}
                  timeout="auto"
                  unmountOnExit
                  style={{
                    width: "210px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    color: "white",
                  }}
                >
                  <CustomLink to="/home/products">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                    >
                      <h3
                        style={{
                          fontVariant: "bold",
                          lineHeight: 0,
                          fontSize: "21px",
                        }}
                      >
                        Products
                      </h3>
                      <PlaylistAddIcon
                        style={{
                          fontSize: "40px",
                          marginRight: "-11px",
                          color: "#1ca0c4",
                        }}
                      />
                    </div>
                  </CustomLink>
                  <CustomLink to="/home/headings">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                    >
                      <h3
                        style={{
                          fontVariant: "bold",
                          lineHeight: 0,
                          fontSize: "21px",
                        }}
                      >
                        Headings
                      </h3>
                      <img
                        style={{ marginTop: "3px" }}
                        src={dataIcon}
                        width="26px"
                        height="26px"
                        alt="data icon"
                      />
                    </div>
                  </CustomLink>

                  <CustomLink to="/home/images">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                    >
                      <h3
                        style={{
                          fontVariant: "bold",
                          lineHeight: 0,
                          fontSize: "21px",
                        }}
                      >
                        Images
                      </h3>
                      <PermMediaIcon
                        style={{
                          fontSize: "28px",
                          marginTop: "5px",
                          color: "#1ca0c4",
                        }}
                      />
                    </div>
                  </CustomLink>

                  <CustomLink to="templates">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                    >
                      <h3
                        style={{
                          fontVariant: "bold",
                          lineHeight: 0,
                          fontSize: "21px",
                        }}
                      >
                        Templates
                      </h3>
                      <img
                        src={templatesIcon}
                        style={{ marginTop: "7px", marginRight: "2px" }}
                        width="24px"
                        height="24px"
                        alt="templates icon"
                      />
                    </div>
                  </CustomLink>

                  {props.roleLevel < 1 && (
                    <CustomLink to="/home/productTypes">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      >
                        <h3
                          style={{
                            fontVariant: "bold",
                            lineHeight: 0,
                            fontSize: "21px",
                          }}
                        >
                          Product Type
                        </h3>
                        <img
                          src={typeIcon}
                          style={{ marginTop: "5px", marginRight: "5px" }}
                          width="24px"
                          height="24px"
                          alt="Product type icon"
                        />
                      </div>
                    </CustomLink>
                  )}

                  {props.roleLevel < 2 && (
                    <CustomLink to="/home/productCategory">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      >
                        <h3
                          style={{
                            fontVariant: "bold",
                            lineHeight: 0,
                            fontSize: "21px",
                          }}
                        >
                          Product Category
                        </h3>
                        <img
                          src={categoryIcon}
                          style={{ marginTop: "8px", marginRight: "2px" }}
                          width="24px"
                          height="24px"
                          alt="category icon"
                        />
                      </div>
                    </CustomLink>
                  )}

                  {props.roleLevel < 2 && (
                    <CustomLink to="/home/users">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      >
                        <h3
                          style={{
                            fontVariant: "bold",
                            lineHeight: 0,
                            fontSize: "21px",
                          }}
                        >
                          Users
                        </h3>
                        <PersonIcon
                          style={{
                            fontSize: "30px",
                            marginTop: "5px",
                            color: "#1ca0c4",
                          }}
                        />
                      </div>
                    </CustomLink>
                  )}

                  {props.roleLevel < 1 && (
                    <CustomLink to="/home/roles">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      >
                        <h3
                          style={{
                            fontVariant: "bold",
                            lineHeight: 0,
                            fontSize: "21px",
                          }}
                        >
                          Roles
                        </h3>
                        <ManageAccountsIcon
                          style={{
                            fontSize: "30px",
                            marginTop: "5px",
                            color: "#1ca0c4",
                          }}
                        />
                      </div>
                    </CustomLink>
                  )}

                  {props.roleLevel < 1 && (
                    <CustomLink to="/home/companyProfile">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                      >
                        <h3
                          style={{
                            fontVariant: "bold",
                            lineHeight: 0,
                            fontSize: "21px",
                          }}
                        >
                          Company Profile
                        </h3>
                        <ApartmentIcon
                          style={{
                            color: "#1ca0c4",
                            fontSize: "29px",
                            marginTop: "2px",
                            marginRight: "2px",
                          }}
                        />
                      </div>
                    </CustomLink>
                  )}
                </Collapse>
              )}
            </div>
          </div>
        </div>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default" }}>
        {props.children}
      </Box>
    </Box>
  );
}
