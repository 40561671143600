import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useRef } from "react";

import ReactToPrint from "react-to-print";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TemplateView(props) {
  let componentRef = useRef();

  const handleClose = () => {
    props.closeTemplate();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#1CA0C4" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {props.templateName}
            </Typography>
            <ReactToPrint
              trigger={() => (
                <Button autoFocus color="inherit">
                  Print
                </Button>
              )}
              content={() => componentRef}
            />
          </Toolbar>
        </AppBar>
        <div
          style={{
            display: "flex",
            flex: 1,
            padding: "1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              width: "1200px",
              // height: "1550px",
              border: "2px solid grey",
            }}
          >
            {<props.template ref={(el) => (componentRef = el)} />}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
