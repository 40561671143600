import Login from "./views/Login/Login";
import Register from "./views/Register/Register";
import Main from "./views/Main/Main";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import NotFound from "./components/NotFound";

import axios from "axios";
import config from "./config";

import { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CircularProgress } from "@mui/material";

function App() {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [roleLevel, setRoleLevel] = useState(3);
  const [company, setCompany] = useState("");

  const timeoutIdRef = useRef(null);
  const [token, setToken] = useState(null);

  const resetTimer = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    const newTimeoutId = setTimeout(handleLogout, 10 * 60 * 1000);
    timeoutIdRef.current = newTimeoutId;
  };

  useEffect(async () => {
    const storedToken = localStorage.getItem("tds_token");
    if (storedToken && storedToken != "null") {
      const url = config.uri + "public/" + config.version + "/decodeToken";
      await axios
        .get(url, { params: { token: storedToken } })
        .then((e) => {
          setName(e.data.payload.name);
          setRole(e.data.payload.role);
          setCompany(e.data.payload.company);
          setToken(storedToken);
        })
        .catch((err) => {
          localStorage.setItem("tds_token", "null");
        });
    }
  }, []);

  useEffect(() => {
    resetTimer();
    const handleUserActivity = () => {
      resetTimer();
    };

    window.addEventListener("mousedown", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    return () => {
      window.removeEventListener("mousedown", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    };
  }, []);

  const handleSetToken = (token) => {
    localStorage.setItem("tds_token", token);
    setToken(token);
  };

  const handleLogout = () => {
    handleSetToken(null);
  };

  const handleSetName = (name) => {
    setName(name);
  };

  const handleSetCompany = (company) => {
    setCompany(company);
  };

  const handleSetRole = (role) => {
    const roles = ["USER", "MANAGER", "HOD", "ADMIN"];

    if (role === roles[3]) {
      setRoleLevel(0);
    } else if (role === roles[2]) {
      setRoleLevel(1);
    } else if (role === roles[1]) {
      setRoleLevel(2);
    } else {
      setRoleLevel(3);
    }

    setRole(role);
  };

  return (
    <div className="App" id="app-container">
      <Router>
        <Routes>
          <Route
            path="/home/*"
            element={
              token ? (
                <Main
                  company={company}
                  token={token}
                  name={name}
                  role={role}
                  roleLevel={roleLevel}
                  setToken={(x) => handleSetToken(x)}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/"
            element={
              token ? (
                <Navigate
                  to={
                    sessionStorage.getItem("lastVisitedPage")
                      ? sessionStorage.getItem("lastVisitedPage")
                      : "/home"
                  }
                />
              ) : (
                <Login
                  setCompany={handleSetCompany}
                  setToken={handleSetToken}
                  setName={handleSetName}
                  setRole={handleSetRole}
                />
              )
            }
          />

          <Route path="/register" element={<Register />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
