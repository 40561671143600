import React from "react";
import axios from "axios";
import config from "../config";

export default class Template1 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      LogoImage: require("../Assets/caseWellLogo.png"),
      ProductName: "Product Name",
      ProductImage: require("../Assets/placeholder.png"),
      headings: [],
      text: [],
      disclaimer: "",
    };
  }

  async componentDidMount() {
    const urllogo = config.uri + "public/" + config.version + "/templateData";

    await axios
      .get(urllogo, { params: { company: this.props.company } })
      .then((e) => {
        const base64String = btoa(
          new Uint8Array(e.data?.Logo?.data?.data).reduce(function (
            data,
            byte
          ) {
            return data + String.fromCharCode(byte);
          },
          "")
        );

        this.setState({
          disclaimer: e.data?.Disclaimer,
        });

        if (base64String) {
          this.setState({ LogoImage: `data:image/png;base64,${base64String}` });
        }
      });

    if (this.props.productName) {
      this.setState({ ProductName: this.props.productName });
    }

    if (this.props.productImage) {
      this.setState({
        ProductImage: `${config.uri}admin/${config.version}/getFile?company=${this.props.company}&key=${this.props.productImage}`,
      });
    }

    if (this.props.custom == true) {
      let tempCustomHeadings = [];
      let tempCustomText = [];
      for (let i = 0; i < this.props.headings.length; i++) {
        for (let j = 0; j < this.props.customHeadings.length; j++) {
          if (this.props.headings[i] == this.props.customHeadings[j]) {
            tempCustomHeadings.push(this.props.headings[i]);
            tempCustomText.push(this.props.text[i]);
          }
        }
      }

      this.setState({ headings: tempCustomHeadings });
      this.setState({ text: tempCustomText });
    } else {
      if (this.props.headings) this.setState({ headings: this.props.headings });
      if (this.props.text) this.setState({ text: this.props.text });
    }
  }

  render() {
    return (
      <div style={{ padding: "1.3rem 3rem" }}>
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "space-between",
            marginBottom: "25px",
          }}
        >
          <h2
            style={{
              lineHeight: "0",
              color: "#1CA0C4",
              marginTop: "35px",
              fontSize: "26px",
              marginLeft: "5px",
              fontWeight: "800",
            }}
          >
            {this.state.ProductName}
          </h2>
          <img height={60} src={this.state.LogoImage} alt="Logo" />
        </div>

        <div
          style={{
            width: "100%",
            borderBottom: "2px solid #D9D9D9",
          }}
        ></div>
        <div
          style={{
            width: "100%",
            height: `${311 * 1.48}px`,
            marginTop: "4px",
            backgroundColor: "#5BBED9",
            display: "grid",
            gridTemplateRows: "repeat(3, auto)",
            rowGap: "1px",
          }}
        >
          {[0, 3, 6, 9].map((start, index) => (
            <div
              key={index}
              style={{
                borderBottom: index < 3 ? "1px solid #9FA1A1" : "none",
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "10px",
                padding: "0 28px",
              }}
            >
              {this.state.headings.slice(start, start + 3).map((x, i) => (
                <div key={x + i}>
                  <p
                    style={{
                      marginTop: "12px",
                      lineHeight: "16px",
                      fontSize: "24px",
                      color: "#404040",
                      fontWeight: "600",
                    }}
                  >
                    <b>{x}</b>
                  </p>
                  <p
                    style={{
                      lineHeight: "24px",
                      fontSize: "24px",
                      color: "#404040",
                      marginTop: "-7px",
                      marginBottom: "2px",
                    }}
                  >
                    {this.state.text[start + i]}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div
          style={{
            width: "100%",
            height: `${629 * 1.48}px`,
            backgroundColor: "#838383",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
          }}
        >
          {[12, 15, 18, 21, 24].map((start, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "15px",
                padding: "10px 28px",
                marginTop: `${start <= 12 ? "0px" : "25px"}`,
              }}
            >
              {this.state.headings.slice(start, start + 3).map((x, i) => (
                <div key={x + i}>
                  <p
                    style={{
                      marginTop: "12px",
                      lineHeight: "16px",
                      fontSize: "24px",
                      color: "#fff",
                      fontWeight: "600",
                    }}
                  >
                    <b>{x}</b>
                  </p>
                  <p
                    style={{
                      lineHeight: "24px",
                      fontSize: "24px",
                      color: "#fff",
                      marginTop: "-7px",
                      marginBottom: "2px",
                    }}
                  >
                    {this.state.text[start + i]}
                  </p>
                </div>
              ))}
            </div>
          ))}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
              padding: "0px 28px",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: "1",
                flexDirection: "column",
              }}
            >
              {this.state.headings.slice(24, 27).map((x, i) => (
                <div key={x + i}>
                  <p
                    style={{
                      marginTop: "45px",
                      lineHeight: "16px",
                      fontSize: "24px",
                      color: "#fff",
                      fontWeight: "600",
                    }}
                  >
                    <b>{x}</b>
                  </p>
                  <p
                    style={{
                      lineHeight: "24px",
                      fontSize: "24px",
                      color: "#fff",
                      marginTop: "-7px",
                      marginBottom: "2px",
                    }}
                  >
                    {this.state.text[24 + i]}
                  </p>
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "410px",
                height: "350px",
                marginBottom: "10px",
                marginLeft: "-60px",
                position: "relative",
              }}
            >
              <img
                src={this.state.ProductImage}
                alt="Product"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  margin: "auto",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flex: "1",
                flexDirection: "column",
                marginLeft: "6px",
              }}
            >
              {this.state.headings.slice(27, 30).map((x, i) => (
                <div key={x + i}>
                  <p
                    style={{
                      marginTop: "45px",
                      lineHeight: "16px",
                      fontSize: "24px",
                      color: "#fff",
                      fontWeight: "600",
                    }}
                  >
                    <b>{x}</b>
                  </p>
                  <p
                    style={{
                      lineHeight: "24px",
                      fontSize: "24px",
                      color: "#fff",
                      marginTop: "-7px",
                      marginBottom: "2px",
                    }}
                  >
                    {this.state.text[27 + i]}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            overflowY: "hidden",
            paddingLeft: "2px",
            padding: "5px 10px",
            backgroundColor: "#efeeee",
            height: "130px",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          }}
        >
          <p style={{ lineHeight: "0", fontSize: "18px", color: "#1ca0c4" }}>
            <b>Disclaimer:</b>
          </p>
          <p style={{ lineHeight: "15px", fontSize: "15px", color: "#404040" }}>
            {this.state.disclaimer}
          </p>
        </div>
      </div>
    );
  }
}
