import React, { useState, useEffect } from "react";
import axios from "axios";

import CustomLink from "../../components/CustomLink";
import SnackbarAlert from "../../components/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import DeleteAlert from "../../components/DeleteAlert";

import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import config from "../../config";
import styles from "./productTypes.module.css";

export default function ProductTypes(props) {
  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  }, []);

  const addProductTypeImg = require("../../Assets/addUserIcon.png");
  const sortAsc = require("../../Assets/sortAsc.png");
  const sortDesc = require("../../Assets/sortDesc.png");

  //for delete alert
  const [deleteItem, setDeleteItem] = useState(null);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  //For searching, sorting and pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("asc");
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const [edit_oldType, edit_setOldType] = useState("");
  const [edit_newType, edit_setNewType] = useState("");

  const [open, setOpen] = useState(false);
  const [newType, setNewType] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [editBtnLoading, setEditBtnLoading] = useState(false);

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const fetchData = async () => {
    const url = config.uri + "admin/" + config.version + "/product_type";
    axios
      .get(url, {
        params: {
          company: props.company,
          for: "mainPage",
          search: search,
          sort: sortBy,
          page: currentPage,
        },
      })
      .then((e) => {
        {
          setData(e.data?.types);
          setPages(Math.ceil(e.data?.total / 25));
          setLoading(false);
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    props.setHeader(() => (
      <div
        onClick={() => setOpen(true)}
        style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
      >
        <img
          src={addProductTypeImg}
          width="28px"
          height="28px"
          style={{ marginRight: "14px" }}
        />
        <Typography variant="h5" noWrap component="div">
          Add Product Type
        </Typography>
      </div>
    ));
    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  useEffect(() => {
    fetchData();
  }, [sortBy, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    fetchData();
  }, [search]);

  const handleDelete = (x) => {
    setDeleteItem(x);
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    const id = deleteItem._id;
    const type = deleteItem.Product_Type_Name;

    const url = config.uri + "admin/" + config.version + "/delete_product_type";
    await axios
      .get(url, { params: { id, type, company: props.company } })
      .then((e) => {
        if (e.data.message == "error") {
          setSnackbarMsg(e.data.reason);
          setSnackbarVariant("error");
          setSnackbarOpen(true);
        } else {
          setSnackbarMsg("Product Type Deleted.");
          setSnackbarVariant("success");
          setSnackbarOpen(true);
          setLoading(true);
          fetchData();
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      })
      .finally(() => {
        setDeleteItem(null);
      });
  };

  const handleNewProductType = async () => {
    if (!(props.roleLevel < 1)) {
      setSnackbarMsg("You are not eligible to add new roles.");
      setSnackbarVariant("warning");
      setSnackbarOpen(true);
    }
    const valid = validate();
    if (valid) {
      let params = {
        Product_Type_Name: newType?.trim(),
        company: props.company,
      };
      let url = config.uri + "admin/" + config.version + "/product_type";
      await axios
        .post(url, params)
        .then((e) => {
          setSnackbarMsg("Success!");
          setSnackbarVariant("success");
          setSnackbarOpen(true);
          fetchData();
          setOpen(false);
          setNewType("");
        })
        .catch((err) => {
          setSnackbarMsg(err.response.data || "error");
          setSnackbarVariant("error");
          setSnackbarOpen(true);
        });
    }
  };

  const handleEditProductType = async () => {
    if (!(props.roleLevel < 1)) {
      setSnackbarMsg("You are not eligible to add new roles.");
      setSnackbarVariant("warning");
      setSnackbarOpen(true);
    }

    if (!edit_newType || edit_newType.trim() == "") {
      setSnackbarMsg("Not a valid product type name");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    setEditBtnLoading(true);
    try {
      let url = config.uri + "admin/" + config.version + "/edit_product_type";
      const params = {
        company: props.company,
        old_type: edit_oldType,
        new_type: edit_newType,
      };

      await axios.post(url, params).then((e) => {
        setSnackbarMsg("Success");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        fetchData();
      });
    } catch (error) {
      setSnackbarMsg(
        error.response.data ||
          "error while updating product type, try again later"
      );
      setSnackbarVariant("error");
      setSnackbarOpen(true);
    } finally {
      setEditBtnLoading(false);
      setOpenEditDialog(false);
    }
  };

  const validate = () => {
    if (!newType || newType.trim() == "") {
      setSnackbarMsg("Not a valid product type name");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
    }
    return true;
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress style={{ color: "#1CA0C4" }} />
        </div>
      ) : (
        <div className={styles.main}>
          <div className={styles.headerRow}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flex: "1",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <FormControl
                sx={{
                  mb: 1,
                  mt: 1,
                  width: "600px",
                }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Search" edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FormControl>
            </div>
          </div>
          <table cellSpacing="0">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>
                  <span>Product Type Name</span>
                  {sortBy == "asc" ? (
                    <span
                      onClick={() => {
                        setSortBy("desc");
                      }}
                    >
                      <img
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        setSortBy("asc");
                      }}
                    >
                      <img
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th style={{ textAlign: "center" }}>Edit</th>
                <th style={{ textAlign: "center" }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((x, i) => (
                  <tr key={`key${i}`}>
                    <td>{(currentPage - 1) * 25 + (i + 1)}</td>
                    <td>{x.Product_Type_Name}</td>
                    <td style={{ textAlign: "center" }}>
                      <EditNoteOutlinedIcon
                        onClick={() => {
                          edit_setOldType(x.Product_Type_Name);
                          setOpenEditDialog(true);
                        }}
                        sx={{ cursor: "pointer", color: "#1ca0c4" }}
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <DeleteForeverOutlinedIcon
                        sx={{ cursor: "pointer", color: "#1ca0c4" }}
                        onClick={() => handleDelete(x)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className={styles.pagination}>
            <span>
              <ArrowBackIosIcon
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
                onClick={() => {
                  if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1);
                }}
              />
            </span>
            <b>{`${currentPage} of ${pages}`}</b>
            <span>
              <ArrowForwardIosIcon
                onClick={() => {
                  if (currentPage + 1 <= pages) setCurrentPage(currentPage + 1);
                }}
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
              />
            </span>
          </div>
        </div>
      )}

      <DeleteAlert
        open={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        onDelete={handleDeleteConfirmed}
      />

      {/* Dialog for add Product Type */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{
            fontSize: "25px",
            backgroundColor: "#1ca0c4",
            color: "white",
            marginBottom: "20px",
          }}
        >
          Add product type.
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px" }}>
            Enter name of new product type.
          </DialogContentText>
          <TextField
            value={newType}
            onChange={(e) => setNewType(e.target.value)}
            autoFocus
            label="Product Type"
            fullWidth
            variant="outlined"
            margin="dense"
            sx={{ marginTop: "10px" }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            sx={{
              backgroundColor: "#1ca0c4",
              color: "white",
              "&:hover": {
                backgroundColor: "#147791",
              },
            }}
            onClick={() => handleNewProductType()}
            variant="contained"
          >
            {btnLoading ? (
              <CircularProgress size={"1.3rem"} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
            color="error"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Edit Product Type */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      >
        <DialogTitle
          sx={{
            fontSize: "25px",
            backgroundColor: "#1ca0c4",
            color: "white",
            marginBottom: "20px",
          }}
        >
          Edit product type - {edit_oldType}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px" }}>
            Enter name of new product type.
          </DialogContentText>
          <TextField
            value={edit_newType}
            onChange={(e) => edit_setNewType(e.target.value)}
            autoFocus
            label="New Product Type"
            fullWidth
            variant="outlined"
            margin="dense"
            sx={{ marginTop: "10px" }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            sx={{
              backgroundColor: "#1ca0c4",
              color: "white",
              "&:hover": {
                backgroundColor: "#147791",
              },
            }}
            onClick={() => handleEditProductType()}
            variant="contained"
          >
            {editBtnLoading ? (
              <CircularProgress size={"1.3rem"} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
            color="error"
            variant="contained"
            onClick={() => setOpenEditDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
    </div>
  );
}
