import React, { useEffect, useState } from "react";
import SnackbarAlert from "../../components/SnackbarAlert";
import config from "../../config";
import axios from "axios";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import DeleteAlert from "../../components/DeleteAlert";

import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import styles from "./templates.module.css";
import CustomLink from "../../components/CustomLink";
import Typography from "@mui/material/Typography";

import TemplateView from "../../components/TemplateView";
import Template1 from "../../components/Template1";
import Template2 from "../../components/Template2";
import Template3 from "../../components/Template3";
import Template4 from "../../components/Template4";
import Template5 from "../../components/Template5";
import Template6 from "../../components/Template6";
import Template7 from "../../components/Template7";

export default function Templates(props) {
  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  }, []);
  const view = require("../../Assets/viewIcon.png");
  const clone = require("../../Assets/clone.png");
  const deleteIcon = require("../../Assets/delete.png");

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  //for delete alert
  const [deleteItem, setDeleteItem] = useState(null);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  const [templates, setTemplates] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [derivedFrom, setDerivedFrom] = useState("");
  const [newTemplateName, setNewTemplateName] = useState("");

  const [openTemplate1, setOpenTemplate1] = useState(false);
  const [openTemplate2, setOpenTemplate2] = useState(false);
  const [openTemplate3, setOpenTemplate3] = useState(false);
  const [openTemplate4, setOpenTemplate4] = useState(false);
  const [openTemplate5, setOpenTemplate5] = useState(false);
  const [openTemplate6, setOpenTemplate6] = useState(false);
  const [openTemplate7, setOpenTemplate7] = useState(false);

  const [selectedHeadings, setSelectedHeadings] = useState([]);
  const [headings, setHeadings] = useState(null);

  const template1Dims = [13, 17];
  const template2Dims = [15, 19];
  const template3Dims = [17, 23];
  const template4Dims = [20, 24];
  const template5Dims = [23, 30];
  const template6Dims = [15, 33];
  const template7Dims = [15, 33];

  useEffect(() => {
    props.setHeader(() => (
      <div style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}>
        <Typography variant="h5" noWrap component="div">
          Templates
        </Typography>
      </div>
    ));
    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  const fetchTemplates = async () => {
    const url = config.uri + "admin/" + config.version + "/templates";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setTemplates(e.data);
      })
      .catch((err) => {
        setSnackbarMsg("Error in fetching templates");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const fetchHeadings = async () => {
    const url = config.uri + "admin/" + config.version + "/headings";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setHeadings(e.data);
      })
      .catch((err) => {
        setSnackbarMsg("Error in fetching headings");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    fetchTemplates();
    fetchHeadings();
  }, []);

  const handleAddHeadings = (e, i) => {
    let newArray = [...selectedHeadings];
    newArray[i] = e.target.value;
    setSelectedHeadings(newArray);
  };

  const handleDeleteInput = (i) => {
    const newHeadings = [...selectedHeadings];
    newHeadings.splice(i, 1);
    setSelectedHeadings(newHeadings);
  };

  const handleAddInput = () => {
    setSelectedHeadings([...selectedHeadings, ""]);
  };

  const cloneTemplate = (template) => {
    setDerivedFrom(template);
    setOpenModal(true);
  };

  const sendCloneTemplateData = async () => {
    const url = config.uri + "admin/" + config.version + "/template";

    const params = {
      name: newTemplateName,
      derivedFrom: derivedFrom,
      headings: selectedHeadings,
      company: props.company,
    };

    await axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("Successfully created a new template!");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        document.getElementById("close").click();
        reset();
      })
      .catch((err) => {
        setSnackbarMsg("Unable to create product due to server error!");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        reset();
        return;
      });
  };

  const handleDelete = (x) => {
    setDeleteItem(x);
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    const id = deleteItem._id;
    const name = deleteItem.name;

    const url = config.uri + "admin/" + config.version + "/deleteTemplate";
    await axios
      .get(url, { params: { id: id, name: name, company: props.company } })
      .then((e) => {
        if (e.data.message == "error") {
          setSnackbarMsg(e.data.reason);
          setSnackbarVariant("error");
          setSnackbarOpen(true);
        } else {
          setSnackbarMsg("success");
          setSnackbarVariant("success");
          setSnackbarOpen(true);
          fetchTemplates();
        }
      })
      .catch((err) => {
        setSnackbarMsg("Unable to delete template due to server error!");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      })
      .finally(() => {
        setDeleteItem(null);
      });
  };

  const handleDeleteTemplate = async (id, name) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this template?"
    );
    if (!confirmDelete) return;
  };

  const reset = () => {
    setSelectedHeadings([]);
    setNewTemplateName("");
    setDerivedFrom("");
  };

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <table cellSpacing="0">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Template Name</th>
              <th>Derived From</th>
              <th style={{ textAlign: "center" }}>View</th>
              {props.roleLevel < 2 && (
                <th style={{ textAlign: "center" }}>Clone</th>
              )}
              {props.roleLevel < 2 && (
                <th style={{ textAlign: "center" }}>Delete</th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Template 1</td>
              <td>N/A</td>
              <td
                style={{ cursor: "pointer", textAlign: "center" }}
                onClick={() => {
                  setOpenTemplate1(!openTemplate1);
                }}
              >
                <VisibilityOutlinedIcon
                  sx={{ cursor: "pointer", color: "#1ca0c4" }}
                />
              </td>
              {props.roleLevel < 2 && (
                <td
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={() => cloneTemplate("Template1")}
                >
                  <FileCopyOutlinedIcon
                    sx={{ cursor: "pointer", color: "#1ca0c4" }}
                  />
                </td>
              )}
              {props.roleLevel < 2 && (
                <td style={{ textAlign: "center" }}>N/A</td>
              )}
            </tr>
            <tr>
              <td>2</td>
              <td>Template 2</td>
              <td>N/A</td>
              <td
                style={{ cursor: "pointer", textAlign: "center" }}
                onClick={() => {
                  setOpenTemplate2(!openTemplate2);
                }}
              >
                <VisibilityOutlinedIcon
                  sx={{ cursor: "pointer", color: "#1ca0c4" }}
                />
              </td>
              {props.roleLevel < 2 && (
                <td
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={() => cloneTemplate("Template2")}
                >
                  <FileCopyOutlinedIcon
                    sx={{ cursor: "pointer", color: "#1ca0c4" }}
                  />
                </td>
              )}
              {props.roleLevel < 2 && (
                <td style={{ textAlign: "center" }}>N/A</td>
              )}
            </tr>
            <tr>
              <td>3</td>
              <td>Template 3</td>
              <td>N/A</td>
              <td
                style={{ cursor: "pointer", textAlign: "center" }}
                onClick={() => {
                  setOpenTemplate3(!openTemplate3);
                }}
              >
                <VisibilityOutlinedIcon
                  sx={{ cursor: "pointer", color: "#1ca0c4" }}
                />
              </td>
              {props.roleLevel < 2 && (
                <td
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={() => cloneTemplate("Template3")}
                >
                  <FileCopyOutlinedIcon
                    sx={{ cursor: "pointer", color: "#1ca0c4" }}
                  />
                </td>
              )}
              {props.roleLevel < 2 && (
                <td style={{ textAlign: "center" }}>N/A</td>
              )}
            </tr>
            <tr>
              <td>4</td>
              <td>Template 4</td>
              <td>N/A</td>
              <td
                style={{ cursor: "pointer", textAlign: "center" }}
                onClick={() => {
                  setOpenTemplate4(!openTemplate4);
                }}
              >
                <VisibilityOutlinedIcon
                  sx={{ cursor: "pointer", color: "#1ca0c4" }}
                />
              </td>
              {props.roleLevel < 2 && (
                <td
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={() => cloneTemplate("Template3")}
                >
                  <FileCopyOutlinedIcon
                    sx={{ cursor: "pointer", color: "#1ca0c4" }}
                  />
                </td>
              )}
              {props.roleLevel < 2 && (
                <td style={{ textAlign: "center" }}>N/A</td>
              )}
            </tr>
            <tr>
              <td>5</td>
              <td>Template 5</td>
              <td>N/A</td>
              <td
                style={{ cursor: "pointer", textAlign: "center" }}
                onClick={() => {
                  setOpenTemplate5(!openTemplate5);
                }}
              >
                <VisibilityOutlinedIcon
                  sx={{ cursor: "pointer", color: "#1ca0c4" }}
                />
              </td>
              {props.roleLevel < 2 && (
                <td
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={() => cloneTemplate("Template3")}
                >
                  <FileCopyOutlinedIcon
                    sx={{ cursor: "pointer", color: "#1ca0c4" }}
                  />
                </td>
              )}
              {props.roleLevel < 2 && (
                <td style={{ textAlign: "center" }}>N/A</td>
              )}
            </tr>
            <tr>
              <td>6</td>
              <td>Template 6</td>
              <td>N/A</td>
              <td
                style={{ cursor: "pointer", textAlign: "center" }}
                onClick={() => {
                  setOpenTemplate6(!openTemplate6);
                }}
              >
                <VisibilityOutlinedIcon
                  sx={{ cursor: "pointer", color: "#1ca0c4" }}
                />
              </td>
              {props.roleLevel < 2 && (
                <td
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={() => cloneTemplate("Template6")}
                >
                  <FileCopyOutlinedIcon
                    sx={{ cursor: "pointer", color: "#1ca0c4" }}
                  />
                </td>
              )}
              {props.roleLevel < 2 && (
                <td style={{ textAlign: "center" }}>N/A</td>
              )}
            </tr>

            <tr>
              <td>7</td>
              <td>Template 7</td>
              <td>N/A</td>
              <td
                style={{ cursor: "pointer", textAlign: "center" }}
                onClick={() => {
                  setOpenTemplate7(!openTemplate7);
                }}
              >
                <VisibilityOutlinedIcon
                  sx={{ cursor: "pointer", color: "#1ca0c4" }}
                />
              </td>
              {props.roleLevel < 2 && (
                <td
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={() => cloneTemplate("Template7")}
                >
                  <FileCopyOutlinedIcon
                    sx={{ cursor: "pointer", color: "#1ca0c4" }}
                  />
                </td>
              )}
              {props.roleLevel < 2 && (
                <td style={{ textAlign: "center" }}>N/A</td>
              )}
            </tr>

            {templates &&
              templates.map((x, i) => (
                <tr key={x.name + i}>
                  <td>{8 + i}</td>
                  <td>{x.name}</td>
                  <td>{x.derivedFrom}</td>
                  <td
                    style={{ cursor: "pointer", textAlign: "center" }}
                    onClick={() => {
                      if (x.derivedFrom == "Template1") {
                        setOpenTemplate1(true);
                      } else if (x.derivedFrom == "Template2") {
                        setOpenTemplate2(true);
                      } else if (x.derivedFrom == "Template3") {
                        setOpenTemplate3(true);
                      } else if (x.derivedFrom == "Template4") {
                        setOpenTemplate4(true);
                      } else if (x.derivedFrom == "Template5") {
                        setOpenTemplate5(true);
                      } else if (x.derivedFrom == "Template6") {
                        setOpenTemplate6(true);
                      } else if (x.derivedFrom == "Template7") {
                        setOpenTemplate7(true);
                      }
                    }}
                  >
                    <VisibilityOutlinedIcon
                      sx={{ cursor: "pointer", color: "#1ca0c4" }}
                    />
                  </td>
                  {props.roleLevel < 2 && (
                    <td style={{ textAlign: "center" }}>N/A</td>
                  )}
                  {props.roleLevel < 2 && (
                    <td style={{ textAlign: "center" }}>
                      <DeleteForeverOutlinedIcon
                        sx={{ cursor: "pointer", color: "#1ca0c4" }}
                        onClick={() => handleDelete(x)}
                      />
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
        <DeleteAlert
          open={isDeleteAlertOpen}
          onClose={() => setIsDeleteAlertOpen(false)}
          onDelete={handleDeleteConfirmed}
        />
      </div>
      <TemplateView
        templateName={"Template 1"}
        template={Template1}
        open={openTemplate1}
        closeTemplate={() => {
          setOpenTemplate1(!openTemplate1);
        }}
      />
      <TemplateView
        templateName={"Template 2"}
        template={Template2}
        open={openTemplate2}
        closeTemplate={() => {
          setOpenTemplate2(!openTemplate2);
        }}
      />
      <TemplateView
        templateName={"Template 3"}
        template={Template3}
        open={openTemplate3}
        closeTemplate={() => {
          setOpenTemplate3(!openTemplate3);
        }}
      />
      <TemplateView
        templateName={"Template 4"}
        template={Template4}
        open={openTemplate4}
        closeTemplate={() => {
          setOpenTemplate4(!openTemplate4);
        }}
      />
      <TemplateView
        templateName={"Template 5"}
        template={Template5}
        open={openTemplate5}
        closeTemplate={() => {
          setOpenTemplate5(!openTemplate5);
        }}
      />
      <TemplateView
        templateName={"Template 6"}
        template={Template6}
        open={openTemplate6}
        closeTemplate={() => {
          setOpenTemplate6(!openTemplate6);
        }}
      />
      <TemplateView
        templateName={"Template 7"}
        template={Template7}
        open={openTemplate7}
        closeTemplate={() => {
          setOpenTemplate7(!openTemplate7);
        }}
      />
      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <DialogTitle
            sx={{
              fontSize: "25px",
              backgroundColor: "#1ca0c4",
              color: "#fff",
              marginBottom: "10px",
            }}
          >
            Clone {derivedFrom}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontSize: "18px" }}>
              This template is derived from {derivedFrom}, so all the design of{" "}
              {derivedFrom} will be copied over to this template and you can
              freely choose any set of headings{" "}
              {(() => {
                let min = 0;
                let max = 30;

                if (derivedFrom == "Template1") {
                  min = template1Dims[0];
                  max = template1Dims[1];
                } else if (derivedFrom == "Template2") {
                  min = template2Dims[0];
                  max = template2Dims[1];
                } else if (derivedFrom == "Template3") {
                  min = template3Dims[0];
                  max = template3Dims[1];
                } else if (derivedFrom == "Template4") {
                  min = template4Dims[0];
                  max = template4Dims[1];
                } else if (derivedFrom == "Template5") {
                  min = template5Dims[0];
                  max = template5Dims[1];
                } else if (derivedFrom == "Template6") {
                  min = template6Dims[0];
                  max = template6Dims[1];
                } else if (derivedFrom == "Template7") {
                  min = template7Dims[0];
                  max = template7Dims[1];
                }
                return `(min - ${min} max - ${max})`;
              })()}
            </DialogContentText>
            <TextField
              margin="dense"
              label="New Template Name"
              type="text"
              fullWidth
              variant="standard"
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
              style={{ marginBottom: "20px" }}
            />
            {selectedHeadings.map((x, i) => (
              <div
                key={i + 244}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="selected-Heading">Heading {i + 1}</InputLabel>
                  <Select
                    MenuProps={{ style: { maxHeight: 400 } }}
                    labelId="selected-Heading"
                    label={"Heading " + (i + 1)}
                    className="headings-target"
                    onChange={(e) => handleAddHeadings(e, i)}
                    value={selectedHeadings[i]}
                  >
                    {headings &&
                      headings.map((x, i) => (
                        <MenuItem key={x.name + i} value={x.name}>
                          {x.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <IconButton>
                  <DeleteIcon
                    onClick={() => handleDeleteInput(i)}
                    fontSize="large"
                  />
                </IconButton>
              </div>
            ))}
          </DialogContent>

          <DialogActions
            style={{
              justifyContent: "flex-start",
              marginLeft: "10px",
              marginTop: "-10px",
            }}
          >
            <Button id="close" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={handleAddInput}>Add Heading</Button>
            <Button onClick={sendCloneTemplateData}>Clone</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
