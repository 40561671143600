import React, { useEffect } from "react";
import styles from "./roles.module.css";

import { Typography } from "@mui/material";

export default function Roles(props) {

  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem('lastVisitedPage', window.location.pathname);
  }, []);

  useEffect(() => {
    props.setHeader(() => (
      <Typography variant="h5" noWrap component="div">
        Role Access
      </Typography>
    ));
    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <h2>
          This section describes different user roles available for assignment
        </h2>
      </div>
      <div className={styles.main}>
        <table cellSpacing="0">
          <thead>
            <tr>
              <th className={styles.th}>S.No.</th>
              <th className={styles.th}>Role</th>
              <th className={styles.th}>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.td}>1.</td>
              <td className={styles.td}>Admin</td>
              <td className={styles.td}>
                Full access to all areas of the application.
              </td>
            </tr>
            <tr>
              <td className={styles.td}>2.</td>
              <td className={styles.td}>HOD</td>
              <td className={styles.td}>
                Full Access to entire Application, except Company Profile and
                Users.
              </td>
            </tr>
            <tr>
              <td className={styles.td}>3.</td>
              <td className={styles.td}>Manager</td>
              <td className={styles.td}>
                Access to entire Application, except Company Profile and Users.
                Manager is not able to Import or Export data in Products section
                and doesn't have delete right across the application.
              </td>
            </tr>
            <tr>
              <td className={styles.td}>4.</td>
              <td className={styles.td}>User</td>
              <td className={styles.td}>Full Access to Home Page.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
