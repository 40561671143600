import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

import axios from "axios";
import config from "../../config";

import DeleteAlert from "../../components/DeleteAlert";

import CustomLink from "../../components/CustomLink";
import SnackbarAlert from "../../components/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { OutlinedInput, InputAdornment, IconButton } from "@mui/material";

import styles from "./users.module.css";

export default function Users(props) {
  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  }, []);

  const roles = ["User", "Manager", "HOD", "Admin"];

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const [username, setUserName] = useState("");
  const [id, setId] = useState("");
  const [department, setDepartment] = useState("");
  const [role, setRole] = useState("");

  const [open, setOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  //for delete alert
  const [deleteItem, setDeleteItem] = useState(null);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  //For searching, sorting and pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("name");
  const [sortType, setSortType] = useState("desc");
  const [search, setSearch] = useState("");

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const sortAsc = require("../../Assets/sortAsc.png");
  const sortDesc = require("../../Assets/sortDesc.png");
  const addUserIcon = require("../../Assets/addUserIcon.png");

  const fetchData = async () => {
    const url = config.uri + "admin/" + config.version + "/user";
    axios
      .get(url, {
        params: {
          company: props.company,
          search: search,
          sort: sortBy,
          page: currentPage,
          sortType: sortType,
        },
      })
      .then((e) => {
        {
          setData(e.data?.users);
          setPages(Math.ceil(e.data?.total / 25));
          setLoading(false);
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const handleEditUser = async () => {
    let url = config.uri + "admin/" + config.version + "/editUser";

    const params = {
      id,
      role: role.toUpperCase(),
      department,
    };

    await axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("Success");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        setOpen(false);
        fetchData();
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        setOpen(false);
      });
  };

  useEffect(() => {
    props.setHeader(() => {
      if (props.roleLevel < 1) {
        return (
          <CustomLink to="/home/addUser">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
              }}
            >
              <img
                src={addUserIcon}
                width="28px"
                height="28px"
                style={{ marginRight: "14px" }}
              />

              <Typography variant="h5" noWrap component="div">
                Add User
              </Typography>
            </div>
          </CustomLink>
        );
      } else {
        return (
          <Typography variant="h5" noWrap component="div">
            Users
          </Typography>
        );
      }
    });

    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  useEffect(() => {
    fetchData();
  }, [sortBy, currentPage, sortType]);

  useEffect(() => {
    setCurrentPage(1);
    fetchData();
  }, [search]);

  function titleCase(str) {
    str = str.toLowerCase().split(" ");
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  }

  const handleDelete = (x) => {
    setDeleteItem(x);
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    const id = deleteItem;

    const url = config.uri + "admin/" + config.version + "/delete_user";
    await axios
      .get(url, { params: { id } })
      .then((e) => {
        if (e.data.message == "error") {
          setSnackbarMsg(e.data.reason);
          setSnackbarVariant("error");
          setSnackbarOpen(true);
        } else {
          setLoading(true);
          setSnackbarMsg("User Deleted.");
          setSnackbarVariant("success");
          setSnackbarOpen(true);
          fetchData();
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      })
      .finally(() => {
        setDeleteItem(null);
      });
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress style={{ color: "#1CA0C4" }} />
        </div>
      ) : (
        <div className={styles.main}>
          <div className={styles.headerRow}>
            <div>
              <FormControl
                sx={{ mb: 1, mt: 1, width: "600px" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Name, Email, Role, Department
                </InputLabel>
                <OutlinedInput
                  value={search}
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Search" edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Name, Email, Role, Department"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FormControl>
            </div>
          </div>
          <table cellSpacing="0">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>
                  <span>Name</span>
                  {sortType == "asc" && sortBy == "name" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("name");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                  {!(sortType == "asc" && sortBy == "name") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("name");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th>
                  <span>Role</span>
                  {sortType == "asc" && sortBy == "role" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("role");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}

                  {!(sortType == "asc" && sortBy == "role") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("role");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th>
                  <span>Login Id</span>
                  {sortType == "asc" && sortBy == "email" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("email");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                  {!(sortType == "asc" && sortBy == "email") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("email");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th>
                  <span>Department</span>
                  {sortType == "asc" && sortBy == "department" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("department");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                  {!(sortType == "asc" && sortBy == "department") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("department");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                {props.roleLevel < 1 && (
                  <th style={{ textAlign: "center" }}>Edit</th>
                )}
                {props.roleLevel < 1 && (
                  <th style={{ textAlign: "center" }}>Delete</th>
                )}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((x, i) => (
                  <tr key={`key${i}`}>
                    <td>{(currentPage - 1) * 25 + (i + 1)}</td>
                    <td>{x.name}</td>
                    <td>{x.role == "HOD" ? "HOD" : titleCase(x.role)}</td>

                    <td>{x.email}</td>
                    <td>{x.department}</td>
                    {props.roleLevel < 1 && (
                      <td style={{ textAlign: "center" }}>
                        <EditNoteOutlinedIcon
                          sx={{ cursor: "pointer", color: "#1ca0c4" }}
                          onClick={() => {
                            setId(x._id);
                            setDepartment(x.department);
                            setRole(x.role);
                            setUserName(x.name);
                            setOpen(true);
                          }}
                        />
                      </td>
                    )}
                    {props.roleLevel < 1 && (
                      <td style={{ textAlign: "center" }}>
                        <DeleteForeverOutlinedIcon
                          sx={{ cursor: "pointer", color: "#1ca0c4" }}
                          onClick={() => handleDelete(x._id)}
                        />
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          <div className={styles.pagination}>
            <span>
              <ArrowBackIosIcon
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
                onClick={() => {
                  if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1);
                }}
              />
            </span>
            <b>{`${currentPage} of ${pages}`}</b>
            <span>
              <ArrowForwardIosIcon
                onClick={() => {
                  if (currentPage + 1 <= pages) setCurrentPage(currentPage + 1);
                }}
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
              />
            </span>
          </div>
        </div>
      )}

      {/* Edit User Dialog */}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{
            fontSize: "25px",
            backgroundColor: "#1ca0c4",
            color: "white",
            marginBottom: "20px",
          }}
        >
          Edit User Details
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px" }}>
            Enter new details for {username}
          </DialogContentText>
          <FormControl fullWidth sx={{ marginTop: "15px" }}>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              MenuProps={{ style: { maxHeight: 400 } }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="Role"
              onChange={(e) => setRole(e.target.value)}
            >
              {roles.map((x, i) => (
                <MenuItem key={x + i} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            autoFocus
            margin="dense"
            id="Headingname"
            label="Department"
            fullWidth
            variant="standard"
          />
        </DialogContent>

        <DialogActions>
          <Button
            sx={{
              backgroundColor: "#1ca0c4",
              color: "white",
              "&:hover": {
                backgroundColor: "#147791",
              },
            }}
            onClick={() => handleEditUser()}
            variant="contained"
          >
            {btnLoading ? (
              <CircularProgress size={"1.3rem"} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
            color="error"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteAlert
        open={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        onDelete={handleDeleteConfirmed}
      />

      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
    </div>
  );
}
