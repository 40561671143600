import React from "react";
import axios from "axios";
import config from "../config";

export default class Template5 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      LogoImage: require("../Assets/caseWellLogo.png"),
      ProductName: "Product Name",
      ProductImage: require("../Assets/placeholder.png"),
      headings: [],
      text: [],
      disclaimer: "",
    };
  }

  async componentDidMount() {
    const urllogo = config.uri + "public/" + config.version + "/templateData";
    await axios
      .get(urllogo, { params: { company: this.props.company } })
      .then((e) => {
        const base64String = btoa(
          new Uint8Array(e.data?.Logo?.data?.data).reduce(function (
            data,
            byte
          ) {
            return data + String.fromCharCode(byte);
          },
          "")
        );

        this.setState({
          disclaimer: e.data?.Disclaimer,
        });

        if (base64String) {
          this.setState({ LogoImage: `data:image/png;base64,${base64String}` });
        }
      });

    if (this.props.productName) {
      this.setState({
        ProductName: this.props.productName,
      });
    }

    if (this.props.productImage) {
      this.setState({
        ProductImage: `${config.uri}admin/${config.version}/getFile?company=${this.props.company}&key=${this.props.productImage}`,
      });
    }

    if (this.props.custom == true) {
      let tempCustomHeadings = [];
      let tempCustomText = [];
      for (let i = 0; i < this.props.headings.length; i++) {
        for (let j = 0; j < this.props.customHeadings.length; j++) {
          if (this.props.headings[i] == this.props.customHeadings[j]) {
            tempCustomHeadings.push(this.props.headings[i]);
            tempCustomText.push(this.props.text[i]);
          }
        }
      }
      this.setState({ headings: tempCustomHeadings });
      this.setState({ text: tempCustomText });
    } else {
      if (this.props.headings) this.setState({ headings: this.props.headings });
      if (this.props.text) this.setState({ text: this.props.text });
    }
  }

  render() {
    return (
      <div style={{ padding: "1.1rem 1.8rem" }}>
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <h2
            style={{
              lineHeight: "0",
              color: "#1CA0C4",
              marginTop: "35px",
              fontSize: "26px",
              marginLeft: "5px",
            }}
          >
            {this.state.ProductName}
          </h2>
          <img height={60} src={this.state.LogoImage}></img>
        </div>

        <div
          style={{
            width: "100%",
            borderBottom: "1px solid black",
          }}
        ></div>
        <div
          style={{
            padding: "5px 10px",
            marginTop: "10px",
            height: "425px",
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            gap: "14px",
            backgroundImage:
              "linear-gradient(168.6deg, #FDFCFB 8.39%, #CECECD 116.03%, #F9F9F9 217.14%)",
          }}
        >
          {this.state.headings &&
            this.state.headings.slice(0, 12).map((x, i) => (
              <div key={x + i}>
                <p
                  style={{
                    lineHeight: "23px",
                    fontSize: "23px",
                  }}
                >
                  <b>{x}</b>
                </p>
                <p
                  style={{
                    lineHeight: "22px",
                    fontSize: "22px",
                    marginTop: "-5px",
                    marginBottom: "2px",
                  }}
                >
                  {this.state.text[i]}
                </p>
              </div>
            ))}
        </div>

        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "row",
            overflow: "hidden",
            height: "900px",
            backgroundImage:
              "linear-gradient(183.31deg, #666666 2.73%, #353838 139.41%, #07718E 247.99%)",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "530px",
              left: "40px",
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              maxWidth: "820px",
              gap: "0px 10px",
              height: "890px",
            }}
          >
            {this.state.headings &&
              this.state.headings.slice(12, 30).map((x, i) => (
                <div key={x + i}>
                  <p
                    style={{
                      color: "white",
                      lineHeight: "23px",
                      fontSize: "23px",
                      fontWeight: "600",
                    }}
                  >
                    <b>{x}</b>
                  </p>
                  <p
                    style={{
                      color: "white",

                      lineHeight: "22px",
                      fontSize: "22px",
                      marginTop: "-5px",
                      marginBottom: "2px",
                    }}
                  >
                    {this.state.text[12 + i]}
                  </p>
                </div>
              ))}
          </div>

          <div
            style={{
              position: "relative",
              top: "70px",
              right: "-600px",
              width: "930px",
              height: "730px",
              borderRadius: "50%",
              transform: "rotate(-90deg)",
              transform: "translate(235px)",
              backgroundImage:
                "linear-gradient(180deg, #e9e8e8 0%, rgba(235, 234,234,0) 119.71%)",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <img
              width={320}
              height={300}
              style={{ zIndex: 1300 }}
              src={this.state.ProductImage}
            ></img>
          </div>
        </div>
        <div
          style={{
            overflowY: "hidden",
            paddingLeft: "2px",
            padding: "5px 10px",
            backgroundColor: "#efeeee",
            height: "110px",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          }}
        >
          <p style={{ lineHeight: "0", fontSize: "18px", color: "#1ca0c4" }}>
            <b>Disclaimer:</b>
          </p>
          <p style={{ lineHeight: "15px", fontSize: "15px", color: "#404040" }}>
            {this.state.disclaimer}
          </p>
        </div>
      </div>
    );
  }
}
