import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";

export default class Template2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      LogoImage: require("../Assets/caseWellLogo.png"),
      ProductName: "Product Name",
      ProductDesc: "",
      ProductImage: require("../Assets/placeholder.png"),
      headings: [],
      text: [],
      disclaimer: "",
    };
  }

  async componentDidMount() {
    const urllogo = config.uri + "public/" + config.version + "/templateData";
    await axios
      .get(urllogo, { params: { company: this.props.company } })
      .then((e) => {
        const base64String = btoa(
          new Uint8Array(e.data?.Logo?.data?.data).reduce(function (
            data,
            byte
          ) {
            return data + String.fromCharCode(byte);
          },
          "")
        );

        this.setState({
          disclaimer: e.data?.Disclaimer,
        });
        if (base64String) {
          this.setState({ LogoImage: `data:image/png;base64,${base64String}` });
        }
      });

    if (this.props.productName) {
      this.setState({ ProductName: this.props.productName });
    }

    if (this.props.productImage) {
      this.setState({
        ProductImage: `${config.uri}admin/${config.version}/getFile?company=${this.props.company}&key=${this.props.productImage}`,
      });
    }

    if (this.props.custom == true) {
      let tempCustomHeadings = [];
      let tempCustomText = [];
      for (let i = 0; i < this.props.headings.length; i++) {
        for (let j = 0; j < this.props.customHeadings.length; j++) {
          if (this.props.headings[i] == this.props.customHeadings[j]) {
            tempCustomHeadings.push(this.props.headings[i]);
            tempCustomText.push(this.props.text[i]);
          }
        }
      }
      this.setState({ headings: tempCustomHeadings });
      this.setState({ text: tempCustomText });
    } else {
      if (this.props.headings) this.setState({ headings: this.props.headings });
      if (this.props.text) this.setState({ text: this.props.text });
    }

    if (this.props.productDesc)
      this.setState({ ProductDesc: this.props.productDesc });
  }

  render() {
    return (
      <div style={{ padding: "1.1rem 1.8rem" }}>
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "space-between",
            marginBottom: "25px",
          }}
        >
          <h2
            style={{
              lineHeight: "0",
              color: "#1CA0C4",
              marginTop: "35px",
              fontSize: "26px",
              marginLeft: "5px",
            }}
          >
            {this.state.ProductName}
          </h2>
          <img height={60} src={this.state.LogoImage}></img>
        </div>
        <div
          style={{
            width: "100%",
            borderBottom: "1px solid black",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: "20px",
            flexDirection: "row",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              padding: "10px",
              borderRadius: "15px",
              boxShadow:
                "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
            }}
          >
            <img width={540} height={550} src={this.state.ProductImage}></img>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "5px 10px",
                backgroundColor: "#efeeee",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "15px",
                height: "192px",
                flexDirection: "column",
                overflowY: "hidden",
                marginBottom: "6px",
              }}
            >
              <span
                style={{
                  color: "#404040",
                  fontWeight: "bold",
                  fontSize: "24px",
                }}
              >
                Product Description
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid black",
                  }}
                ></div>
              </span>
              <p
                style={{
                  textOverflow: "ellipsis",
                  marginTop: "4px",
                  fontSize: "22px",
                }}
              >
                {this.state.ProductDesc}
              </p>
            </div>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                padding: "5px 10px",
                backgroundColor: "#efeeee",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "15px",
                height: "365px",
                flexDirection: "column",
                overflowY: "hidden",
                justifyContent: "space-evenly",
              }}
            >
              {this.state.headings &&
                this.state.headings.slice(0, 3).map((x, i) => (
                  <div key={x + i}>
                    <p
                      style={{
                        color: "#404040",
                        lineHeight: "24px",
                        fontSize: "24px",
                      }}
                    >
                      <b>{x}</b>
                    </p>
                    <p
                      style={{
                        color: "#404040",

                        lineHeight: "24px",
                        fontSize: "24px",
                        marginTop: "-5px",
                        marginBottom: "2px",
                      }}
                    >
                      {this.state.text[i]}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1",
            gap: "20px",
          }}
        >
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              padding: "5px 10px",
              backgroundColor: "#efeeee",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "15px",
              height: "350px",
              flexDirection: "column",
              width: "560px",
              overflowY: "hidden",
              justifyContent: "space-evenly",
            }}
          >
            {this.state.headings &&
              this.state.headings.slice(3, 6).map((x, i) => (
                <div key={x + i}>
                  <p
                    style={{
                      color: "#404040",
                      lineHeight: "24px",
                      fontSize: "24px",
                    }}
                  >
                    <b>{x}</b>
                  </p>
                  <p
                    style={{
                      color: "#404040",

                      lineHeight: "24px",
                      fontSize: "24px",
                      marginTop: "-5px",
                      marginBottom: "2px",
                    }}
                  >
                    {this.state.text[3 + i]}
                  </p>
                </div>
              ))}
          </div>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              flex: "1",
              padding: "5px 10px",
              backgroundColor: "#efeeee",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "15px",
              height: "350px",
              flexDirection: "column",
              overflowY: "hidden",
              justifyContent: "space-evenly",
            }}
          >
            {this.state.headings &&
              this.state.headings.slice(6, 9).map((x, i) => (
                <div key={x + i}>
                  <p
                    style={{
                      color: "#404040",
                      lineHeight: "24px",
                      fontSize: "24px",
                    }}
                  >
                    <b>{x}</b>
                  </p>
                  <p
                    style={{
                      color: "#404040",

                      lineHeight: "24px",
                      fontSize: "24px",
                      marginTop: "-5px",
                      marginBottom: "2px",
                    }}
                  >
                    {this.state.text[6 + i]}
                  </p>
                </div>
              ))}
          </div>
        </div>

        <div
          style={{
            marginTop: "15px",
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            padding: "5px 10px",
            backgroundColor: "#efeeee",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "15px",
            height: "285px",
            flexDirection: "row",
            flexWrap: "wrap",
            overflowY: "hidden",
            lineHeight: "10px",
          }}
        >
          {this.state.headings &&
            this.state.headings.slice(9, 15).map((x, i) => (
              <div key={x + i}>
                <p
                  style={{
                    color: "#404040",
                    lineHeight: "24px",
                    fontSize: "24px",
                  }}
                >
                  <b>{x}</b>
                </p>
                <p
                  style={{
                    color: "#404040",

                    lineHeight: "24px",
                    fontSize: "24px",
                    marginTop: "-5px",
                    marginBottom: "2px",
                  }}
                >
                  {this.state.text[9 + i]}
                </p>
              </div>
            ))}
        </div>
        <div
          style={{
            overflowY: "hidden",
            marginTop: "15px",
            paddingLeft: "2px",
            padding: "5px 10px",
            backgroundColor: "#efeeee",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "15px",
            height: "150px",
          }}
        >
          <p style={{ lineHeight: "0", fontSize: "20px", color: "#1ca0c4" }}>
            <b>Disclaimer:</b>
          </p>
          <p style={{ lineHeight: "16px", fontSize: "15px", color: "#404040" }}>
            {this.state.disclaimer}
          </p>
        </div>
      </div>
    );
  }
}
