import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";

import axios from "axios";
import config from "../../config";

import CustomLink from "../../components/CustomLink";
import SnackbarAlert from "../../components/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";

import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { OutlinedInput, InputAdornment, IconButton } from "@mui/material";

import DeleteAlert from "../../components/DeleteAlert";

import styles from "./prodCategories.module.css";

export default function ProductCategories(props) {
  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  }, []);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [productType, setProductType] = useState();
  const [deleteItem, setDeleteItem] = useState(false);

  //for delete alert
  const [deleteItemStore, setDeleteItemStore] = useState(null);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  //For searching, sorting and pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("productCategory");
  const [search, setSearch] = useState("");
  const [sortType, setSortType] = useState("desc");

  const [btnLoading, setBtnLoading] = useState(false);
  const [newSelectedCategoryProductType, setNewSelectedCategoryProductType] =
    useState("");
  const [newCategory, setNewCategory] = useState("");
  const [open, setOpen] = useState(false);

  const [selectedProdType, setSelectedProdType] = useState([]);
  const [validRow, setValidRow] = useState([]);

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const addIcon = require("../../Assets/addUserIcon.png");
  const sortAsc = require("../../Assets/sortAsc.png");
  const sortDesc = require("../../Assets/sortDesc.png");

  const fetchAllData = async () => {
    const url = config.uri + "admin/" + config.version + "/product_category";
    axios
      .get(url, {
        params: {
          company: props.company,
          for: "MainPage",
          search: search,
          sort: sortBy,
          page: currentPage,
          sortType: sortType,
        },
      })
      .then((e) => {
        {
          setData(e.data?.category);
          setPages(Math.ceil(e.data?.total / 25));
          setLoading(false);
          for (let i = 0; i < e.data.length; i++) {
            if (!e.data[i].productType || e.data[i].productType.trim() == "") {
              setValidRow((prev) => {
                const updated = [...prev];
                updated[i] = false;
                return updated;
              });
            } else {
              setValidRow((prev) => {
                const updated = [...prev];
                updated[i] = true;
                return updated;
              });
            }
          }
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    fetchAllData();
  }, [sortBy, currentPage, sortType]);

  useEffect(() => {
    setCurrentPage(1);
    fetchAllData();
  }, [search]);

  useEffect(() => {
    props.setHeader(() => (
      <div
        onClick={() => setOpen(true)}
        style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
      >
        <img
          src={addIcon}
          width="28px"
          height="28px"
          style={{ marginRight: "14px" }}
        />
        <Typography variant="h5" noWrap component="div">
          Add Product Category
        </Typography>
      </div>
    ));
    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  // fetch product Types
  useEffect(() => {
    const url = config.uri + "admin/" + config.version + "/product_type";
    axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setProductType(e.data);
      })
      .catch((err) => {
        setSnackbarMsg("Unable to fetch product types");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  }, []);

  const handleChangeProductType = (e, i) => {
    setSelectedProdType((prev) => {
      const updated = [...prev];
      updated[i] = e.target.value;
      return updated;
    });
  };

  const save = async (i, prodCategory) => {
    const url = config.uri + "admin/" + config.version + "/update_category";

    const params = {
      category: prodCategory,
      prodType: selectedProdType[i],
      company: props.company,
    };

    await axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("Product Category Updated.");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        fetchAllData();
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  //handle add new product category
  const handleSubmit = async (e) => {
    setBtnLoading(true);

    if (!(props.roleLevel < 2)) {
      setSnackbarMsg("You are not eligible to add a new product category.");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      setBtnLoading(false);
      return;
    }

    if (!newCategory || newCategory.trim() == "") {
      setBtnLoading(false);
      return showError("Category Name");
    }
    if (!newSelectedCategoryProductType) {
      showError("Product Type");
      setBtnLoading(false);
    }

    const url = config.uri + "admin/" + config.version + "/product_category";
    const params = {
      productType: newSelectedCategoryProductType,
      productCategory: newCategory?.trim(),
      company: props.company,
    };

    await axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("Product Category Added.");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        setBtnLoading(false);
        reset();
        setOpen(false);
        fetchAllData();
      })
      .catch((err) => {
        setSnackbarMsg(err.response.data || "error");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        setBtnLoading(false);
      });
  };

  const showError = (reason) => {
    setSnackbarMsg("Error: Enter Valid " + reason);
    setSnackbarVariant("error");
    setSnackbarOpen(true);
    setBtnLoading(false);
    return false;
  };

  const reset = () => {
    setNewCategory("");
    setNewSelectedCategoryProductType("");
  };

  const handleDelete = (x) => {
    setDeleteItemStore(x);
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    const id = deleteItemStore._id;
    const name = deleteItemStore.productCategory;

    const url = config.uri + "admin/" + config.version + "/delete_category";
    await axios
      .get(url, { params: { id, name, company: props.company } })
      .then((e) => {
        if (e.data.message == "error") {
          setSnackbarMsg(e.data.reason);
          setSnackbarVariant("error");
          setSnackbarOpen(true);
          setDeleteItem(false);
        } else {
          setSnackbarMsg("Product Category Deleted.");
          setSnackbarVariant("success");
          setSnackbarOpen(true);
          setDeleteItem(false);
          fetchAllData();
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        setDeleteItem(false);
      })
      .finally(() => {
        setDeleteItemStore(null);
      });
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress style={{ color: "#1CA0C4" }} />
        </div>
      ) : (
        <div className={styles.main}>
          <div className={styles.headerRow}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flex: "1",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <FormControl
                sx={{
                  mb: 1,
                  mt: 1,
                  width: "600px",
                }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Category, Type
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Search" edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Category, Type"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FormControl>
            </div>
          </div>
          <table cellSpacing="0">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>
                  <span>Product Category</span>

                  {sortType == "asc" && sortBy == "productCategory" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productCategory");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}

                  {!(sortType == "asc" && sortBy == "productCategory") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productCategory");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th>
                  <span>Product Type</span>

                  {sortType == "asc" && sortBy == "productType" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productType");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}

                  {!(sortType == "asc" && sortBy == "productType") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productType");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th style={{ textAlign: "center" }}>Save</th>
                <th style={{ textAlign: "center" }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((x, i) => (
                  <tr key={`key${i}`}>
                    <td>{(currentPage - 1) * 25 + (i + 1)}</td>
                    <td>{x.productCategory}</td>
                    {x.productType && x.productType.trim() != "" ? (
                      <td>{x.productType}</td>
                    ) : (
                      <td>
                        <FormControl fullWidth>
                          <InputLabel id="selectType">Select Type</InputLabel>
                          <Select
                            MenuProps={{ style: { maxHeight: 400 } }}
                            labelId="selectType"
                            value={selectedProdType[i]}
                            label="Product Type"
                            onChange={(e) => handleChangeProductType(e, i)}
                          >
                            <MenuItem value="">
                              <em>Select a product type</em>
                            </MenuItem>
                            {productType &&
                              productType.map((x, i) => (
                                <MenuItem
                                  key={x.Product_Type_Name + i}
                                  value={x.Product_Type_Name}
                                >
                                  {x.Product_Type_Name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </td>
                    )}
                    <td style={{ textAlign: "center" }}>
                      {!validRow[i] && (
                        <DownloadDoneOutlinedIcon
                          sx={{ cursor: "pointer", color: "#1ca0c4" }}
                          onClick={() => save(i, x.productCategory)}
                        />
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <DeleteForeverOutlinedIcon
                        sx={{ cursor: "pointer", color: "#1ca0c4" }}
                        onClick={() => handleDelete(x)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className={styles.pagination}>
            <span>
              <ArrowBackIosIcon
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
                onClick={() => {
                  if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1);
                }}
              />
            </span>
            <b>{`${currentPage} of ${pages}`}</b>
            <span>
              <ArrowForwardIosIcon
                onClick={() => {
                  if (currentPage + 1 <= pages) setCurrentPage(currentPage + 1);
                }}
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
              />
            </span>
          </div>
        </div>
      )}

      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />

      {/* Add Product Category Dialog */}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{
            fontSize: "25px",
            backgroundColor: "#1ca0c4",
            color: "white",
            marginBottom: "20px",
          }}
        >
          Add Product Category
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px" }}>
            Product Category Name Is Case Sensitive
          </DialogContentText>
          <TextField
            sx={{ marginBottom: "10px", marginTop: "20px" }}
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            autoFocus
            margin="dense"
            label="Category Name"
            fullWidth
            variant="outlined"
          />
          <FormControl fullWidth sx={{ marginTop: "15px" }}>
            <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
            <Select
              MenuProps={{ style: { maxHeight: 400 } }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newSelectedCategoryProductType}
              label="Product Type"
              onChange={(e) =>
                setNewSelectedCategoryProductType(e.target.value)
              }
            >
              <MenuItem value="">
                <em>Select a product type</em>
              </MenuItem>
              {productType?.map((x, i) => (
                <MenuItem
                  key={x.Product_Type_Name + i}
                  value={x.Product_Type_Name}
                >
                  {x.Product_Type_Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{
              backgroundColor: "#1ca0c4",
              color: "white",
              "&:hover": {
                backgroundColor: "#147791",
              },
            }}
            onClick={() => handleSubmit()}
            variant="contained"
          >
            {btnLoading ? (
              <CircularProgress size={"1.3rem"} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
            color="error"
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteAlert
        open={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        onDelete={handleDeleteConfirmed}
      />
    </div>
  );
}
