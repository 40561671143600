import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";

import axios from "axios";
import config from "../../config";

import SnackbarAlert from "../../components/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import DeleteAlert from "../../components/DeleteAlert";

import {
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import styles from "./headings.module.css";

export default function Headings(props) {
  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  }, []);

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [editHeadingModalLoading, setEditHeadingModalLoading] = useState(false);
  const [data, setData] = useState(null);

  //for delete alert
  const [deleteItem, setDeleteItem] = useState(null);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  //For searching, sorting and pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("asc");
  const [search, setSearch] = useState("");

  const [oldHeading, setOldHeading] = useState("");

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const sortAsc = require("../../Assets/sortAsc.png");
  const sortDesc = require("../../Assets/sortDesc.png");
  const addIcon = require("../../Assets/addUserIcon.png");

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const addHeading = async () => {
    setBtnLoading(true);
    let newHeading = document.getElementById("Headingname").value;
    if (!newHeading || newHeading.trim() == "") {
      setSnackbarMsg("Enter a valid heading");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      setBtnLoading(false);
      return;
    }

    const url = config.uri + "admin/" + config.version + "/heading";
    const params = { name: newHeading, company: props.company };

    await axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("Success");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        setBtnLoading(false);
        setOpen(false);
        fetchData();
      })
      .catch((err) => {
        setSnackbarMsg("Error");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        setBtnLoading(false);
      });
  };

  useEffect(() => {
    props.setHeader(() => (
      <div
        onClick={() => setOpen(true)}
        style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
      >
        <img
          src={addIcon}
          width="28px"
          height="28px"
          style={{ marginRight: "14px" }}
        />
        <Typography variant="h5" noWrap component="div">
          Add Heading
        </Typography>
      </div>
    ));
    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  const editHeading = async () => {
    setEditHeadingModalLoading(true);
    const newHeading = document.getElementById("editHeading").value;
    if (!newHeading || newHeading.trim() == "") {
      setSnackbarMsg("Enter a valid heading");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      setEditHeadingModalLoading(false);
      return;
    }

    if (!oldHeading || oldHeading.trim() == "") {
      setSnackbarMsg("Enter a valid heading");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      setEditHeadingModalLoading(false);
      return;
    }

    const url = config.uri + "admin/" + config.version + "/editHeading";
    const params = { newHeading: newHeading, oldHeading: oldHeading };

    await axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("Success");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        setEditHeadingModalLoading(false);
        setOpenEdit(false);
        fetchData();
      })
      .catch((err) => {
        setSnackbarMsg("Error");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        setEditHeadingModalLoading(false);
      });
  };

  //fetch all data

  const fetchData = async () => {
    const url = config.uri + "admin/" + config.version + "/headings";
    await axios
      .get(url, {
        params: {
          company: props.company,
          for: "mainPage",
          search: search,
          sort: sortBy,
          page: currentPage,
        },
      })
      .then((e) => {
        setData(e.data?.headings);
        setPages(Math.ceil(e.data?.total / 25));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarMsg("Error in fetching headings");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [sortBy, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    fetchData();
  }, [search]);

  const handleDelete = (x) => {
    setDeleteItem(x);
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    const id = deleteItem._id;
    const name = deleteItem.name;

    const url = config.uri + "admin/" + config.version + "/deleteHeading";
    const params = { id, name, company: props.company };

    await axios
      .post(url, params)
      .then((e) => {
        if (e.data.message == "error") {
          setSnackbarMsg(e.data.reason);
          setSnackbarVariant("error");
          setSnackbarOpen(true);
        } else {
          setSnackbarMsg("Deleted Heading Successfully");
          setSnackbarVariant("success");
          setSnackbarOpen(true);
          fetchData();
        }
      })
      .catch((err) => {
        setSnackbarMsg(`Error: ${err.message}`);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      })
      .finally(() => {
        setDeleteItem(null);
      });
  };

  const AddHeadingModal = (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle
        sx={{
          fontSize: "25px",
          backgroundColor: "#1ca0c4",
          color: "white",
          marginBottom: "20px",
        }}
      >
        Add Heading
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: "18px" }}>
          Enter Heading Name (make sure that it is case sensitive).
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="Headingname"
          label="Heading Name"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            backgroundColor: "#1ca0c4",
            color: "white",
            "&:hover": {
              backgroundColor: "#147791",
            },
          }}
          onClick={addHeading}
          variant="contained"
        >
          {btnLoading ? (
            <CircularProgress size={"1.3rem"} color="inherit" />
          ) : (
            "Submit"
          )}
        </Button>
        <Button
          sx={{
            backgroundColor: "black",
            color: "white",
            "&:hover": {
              backgroundColor: "black",
            },
          }}
          color="error"
          variant="contained"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

  const EditHeadingModal = (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openEdit}
      onClose={() => setOpenEdit(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#1ca0c4",
          marginBottom: "10px",
          color: "#fff",
          fontSize: "25px",
        }}
      >
        Edit - {oldHeading}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: "18px" }}>
          Enter New Heading Name (make sure that it is case sensitive).
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="editHeading"
          label="Heading Name"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={editHeading}
          variant="contained"
          sx={{
            backgroundColor: "#1ca0c4",
            color: "white",
            "&:hover": {
              backgroundColor: "#147791",
            },
          }}
        >
          {editHeadingModalLoading ? (
            <CircularProgress size={"1.3rem"} color="inherit" />
          ) : (
            "Submit"
          )}
        </Button>
        <Button
          sx={{
            backgroundColor: "black",
            color: "white",
            "&:hover": {
              backgroundColor: "black",
            },
          }}
          onClick={() => setOpenEdit(false)}
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress style={{ color: "#1CA0C4" }} />
        </div>
      ) : (
        <div className={styles.main}>
          <div className={styles.headerRow}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flex: "1",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <FormControl
                sx={{
                  mb: 1,
                  mt: 1,
                  width: "600px",
                }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Search" edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FormControl>
            </div>
          </div>
          <table cellSpacing="0">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>
                  <span>Heading Name</span>
                  {sortBy == "asc" ? (
                    <span
                      onClick={() => {
                        setSortBy("desc");
                      }}
                    >
                      <img
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        setSortBy("asc");
                      }}
                    >
                      <img
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th style={{ textAlign: "center" }}>Edit</th>
                <th style={{ textAlign: "center" }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((x, i) => (
                  <tr key={`key${i}`}>
                    <td>{(currentPage - 1) * 25 + (i + 1)}</td>
                    <td id="headingContent">{x.name}</td>
                    <td style={{ textAlign: "center" }}>
                      <EditNoteOutlinedIcon
                        onClick={() => {
                          setOpenEdit(true);
                          setOldHeading(x.name);
                        }}
                        sx={{ cursor: "pointer", color: "#1ca0c4" }}
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <DeleteForeverOutlinedIcon
                        onClick={() => handleDelete(x)}
                        sx={{ cursor: "pointer", color: "#1ca0c4" }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className={styles.pagination}>
            <span>
              <ArrowBackIosIcon
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
                onClick={() => {
                  if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1);
                }}
              />
            </span>
            <b>{`${currentPage} of ${pages}`}</b>
            <span>
              <ArrowForwardIosIcon
                onClick={() => {
                  if (currentPage + 1 <= pages) setCurrentPage(currentPage + 1);
                }}
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
              />
            </span>
          </div>
        </div>
      )}

      <DeleteAlert
        open={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        onDelete={handleDeleteConfirmed}
      />

      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
      {AddHeadingModal}
      {EditHeadingModal}
    </div>
  );
}
