import axios from "axios";
import React from "react";
import styles from "./addUser.module.css";
import config from "../../config";

import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import SnackbarAlert from "../../components/SnackbarAlert";
import CustomLink from "../../components/CustomLink";

export default function AddUsers(props) {
  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  }, []);

  useEffect(() => {
    props.setHeader(() => (
      <Typography variant="h5" noWrap component="div">
        Add User
      </Typography>
    ));
    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const [name, setName] = useState("");
  const [dept, setDept] = useState("");
  const [role, setRole] = useState("USER");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const roles = ["User", "Manager", "HOD", "Admin"];

  const handleSubmit = async () => {
    const valid = validate();
    if (valid) {
      let params = {
        name,
        dept,
        role: role.toUpperCase(),
        email,
        mobile,
        company: props.company,
      };
      let url = config.uri + "admin/" + config.version + "/user";
      await axios
        .post(url, params)
        .then((e) => {
          reset();
          setSnackbarMsg("Success!");
          setSnackbarVariant("success");
          setSnackbarOpen(true);
        })
        .catch((err) => {
          setSnackbarMsg("another user already exists with this email");
          setSnackbarVariant("error");
          setSnackbarOpen(true);
        });
    }
  };

  const reset = () => {
    setName("");
    setDept("");
    setRole("User");
    setEmail("");
    setMobile("");
  };

  const validate = () => {
    if (!name || !name.trim()) {
      return showError("name");
    }

    if (!dept || !dept.trim()) {
      return showError("department");
    }

    if (!role || !role.trim()) {
      return showError("role");
    }

    if (!email || !email.trim()) {
      return showError("email");
    }

    if (!email.includes("@") || !email.includes(".")) {
      return showError("email");
    }

    if (!mobile || !mobile.trim()) {
      return showError("mobile number");
    }

    if (mobile.trim().length != 10) {
      return showError("mobile number with only 10 digits");
    }

    for (let i = 0; i < roles.length; i++) {
      if (role.toUpperCase() == roles[i].toUpperCase()) {
        return true;
      }
    }
    return showError("role");
  };

  const showError = (reason) => {
    setSnackbarMsg("Error: Enter Valid " + reason);
    setSnackbarVariant("error");
    setSnackbarOpen(true);
    return false;
  };

  return (
    <div className={styles.container}>
      <div className={styles.section1}>
        <div className={styles.inputContainer}>
          <h3 className={styles.inpTxt}>Name</h3>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.inp}
          />
        </div>
        <div className={styles.inputContainer}>
          <h3 className={styles.inpTxt}>Department</h3>
          <input
            value={dept}
            onChange={(e) => setDept(e.target.value)}
            className={styles.inp}
          />
        </div>
        <div className={styles.inputContainer}>
          <h3 className={styles.inpTxt}>Role</h3>

          <select
            MenuProps={{ style: { maxHeight: 400 } }}
            name={role[0]}
            id="select"
            onChange={(e) => setRole(e.target.value)}
            className={styles.inp}
          >
            {roles.map((role, i) => (
              <option key={role + i}>{role}</option>
            ))}
          </select>
        </div>
        <div className={styles.inputContainer}>
          <h3 className={styles.inpTxt}>Login ID (Email)</h3>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.inp}
          />
        </div>
        <div className={styles.inputContainer}>
          <h3 className={styles.inpTxt}>Mobile</h3>
          <input
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            type="number"
            className={styles.inp}
          />
        </div>
        <div className={styles.btnContainer}>
          <button onClick={handleSubmit} className={styles.btn1}>
            Submit
          </button>
          <CustomLink to="/home/users">
            <button className={styles.btn2}>Cancel</button>
          </CustomLink>
        </div>
      </div>
      <div className={styles.section2}>
        <h3 className={styles.notice}>
          You are about to give access to Tenie DS to a new user. Kindly
          carefully choose the role, as different roles give different
          privileges to the users. Once you have added the user, the user will
          get an email with an autogenerated password through which they can
          start using the application.
        </h3>
      </div>
      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
    </div>
  );
}
