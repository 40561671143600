import React from "react";
import axios from "axios";
import config from "../config";

export default class Template1 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      LogoImage: require("../Assets/caseWellLogo.png"),
      ProductName: "Product Name",
      ProductImage: require("../Assets/placeholder.png"),
      headings: [],
      text: [],
      disclaimer: "",
    };
  }

  async componentDidMount() {
    const urllogo = config.uri + "public/" + config.version + "/templateData";

    await axios
      .get(urllogo, { params: { company: this.props.company } })
      .then((e) => {
        const base64String = btoa(
          new Uint8Array(e.data?.Logo?.data?.data).reduce(function (
            data,
            byte
          ) {
            return data + String.fromCharCode(byte);
          },
          "")
        );

        this.setState({
          disclaimer: e.data?.Disclaimer,
        });

        if (base64String) {
          this.setState({ LogoImage: `data:image/png;base64,${base64String}` });
        }
      });

    if (this.props.productName) {
      this.setState({ ProductName: this.props.productName });
    }

    if (this.props.productImage) {
      this.setState({
        ProductImage: `${config.uri}admin/${config.version}/getFile?company=${this.props.company}&key=${this.props.productImage}`,
      });
    }

    if (this.props.custom == true) {
      let tempCustomHeadings = [];
      let tempCustomText = [];
      for (let i = 0; i < this.props.headings.length; i++) {
        for (let j = 0; j < this.props.customHeadings.length; j++) {
          if (this.props.headings[i] == this.props.customHeadings[j]) {
            tempCustomHeadings.push(this.props.headings[i]);
            tempCustomText.push(this.props.text[i]);
          }
        }
      }

      this.setState({ headings: tempCustomHeadings });
      this.setState({ text: tempCustomText });
    } else {
      if (this.props.headings) this.setState({ headings: this.props.headings });
      if (this.props.text) this.setState({ text: this.props.text });
    }
  }

  render() {
    return (
      <div style={{ padding: "1.3rem 3rem" }}>
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "space-between",
            marginBottom: "25px",
          }}
        >
          <h2
            style={{
              lineHeight: "0",
              color: "#1CA0C4",
              marginTop: "35px",
              fontSize: "26px",
              marginLeft: "5px",
            }}
          >
            {this.state.ProductName}
          </h2>
          <img height={60} src={this.state.LogoImage}></img>
        </div>

        <div
          style={{
            width: "100%",
            borderBottom: "1px solid black",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            flexDirection: "row",
            marginTop: "15px",
          }}
        >
          <div style={{ display: "flex", flex: "1", flexDirection: "column" }}>
            <div
              style={{
                borderRadius: "10px",
                border: "1px solid #ddd",
                marginRight: "20px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                marginBottom: "6px",
              }}
            >
              <img width={527} height={550} src={this.state.ProductImage}></img>
            </div>
            <div
              style={{
                display: "flex",
                flex: "1",
                borderRadius: "12px",
                backgroundColor: "#e8e8e8",
                width: "530px",
                padding: "10px",
                flexDirection: "column",
                marginTop: "10px",
                justifyContent: "space-evenly",
              }}
            >
              {this.state.headings &&
                this.state.headings.slice(0, 6).map((x, i) => (
                  <div key={x + i}>
                    <p
                      style={{
                        lineHeight: "24px",
                        fontSize: "24px",
                      }}
                    >
                      <b>{x}</b>
                    </p>
                    <p
                      style={{
                        lineHeight: "24px",
                        fontSize: "24px",
                        marginTop: "-5px",
                        marginBottom: "2px",
                      }}
                    >
                      {this.state.text[i]}
                    </p>
                  </div>
                ))}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flex: "1",
              height: "1270px",
              borderRadius: "12px",
              padding: "10px",
              backgroundColor: "#e8e8e8",
              flexDirection: "column",
            }}
          >
            {this.state.headings &&
              this.state.headings.slice(6, 19).map((x, i) => (
                <div key={x + i}>
                  <p style={{ lineHeight: "24px", fontSize: "24px" }}>
                    <b>{x}</b>
                  </p>
                  <p
                    style={{
                      lineHeight: "24px",
                      fontSize: "24px",
                      marginTop: "-5px",
                      marginBottom: "2px",
                    }}
                  >
                    {this.state.text[6 + i]}
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div
          style={{
            marginTop: "15px",
            overflowY: "hidden",
            paddingLeft: "2px",
            padding: "5px 10px",
            backgroundColor: "#efeeee",
            height: "130px",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          }}
        >
          <p style={{ lineHeight: "0", fontSize: "18px", color: "#1ca0c4" }}>
            <b>Disclaimer:</b>
          </p>
          <p style={{ lineHeight: "15px", fontSize: "15px", color: "#404040" }}>
            {this.state.disclaimer}
          </p>
        </div>
      </div>
    );
  }
}
