import React, { useState, useEffect } from "react";
import Drawer from "../../components/Drawer";
import styles from "./main.module.css";
import { Routes, Route, useNavigate } from "react-router-dom";

import Users from "../Users/Users";
import Templates from "../Templates/Templates";
import AddUsers from "../Users/AddUsers";
import ProductTypes from "../ProductType/ProductTypes";
import ProductCategories from "../ProductCategory/ProductCategories";
import Images from "../Image/Images";
import Headings from "../Headings/Headings";
import Products from "../Products/Products";
import AddProduct from "../Products/AddProduct";
import CompanyProfile from "../CompanyProfile/CompanyProfile";
import Roles from "../Roles/Roles";

import Home from "../Home/Home";

export default function Main(props) {
  const [drawerHeaderComponent, setDrawerHeaderComponent] = useState(null);

  const [drawerHeaderInfo, setDrawerHeaderInfo] = useState("");

  const [printable, setPrintable] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const lastVisitedPage = sessionStorage.getItem("lastVisitedPage");

    if (props.token && lastVisitedPage) {
      // sessionStorage.removeItem("lastVisitedPage");
      if (lastVisitedPage !== "/home") {
        navigate(lastVisitedPage);
      }
    } else if (props.token) {
      navigate("/home");
    }
  }, [props.token, navigate]);

  return (
    <Drawer
      setToken={props.setToken}
      child={drawerHeaderComponent}
      info={drawerHeaderInfo}
      printable={printable}
      name={props.name}
      company={props.company}
      roleLevel={props.roleLevel}
    >
      <Routes>
        <Route
          path="/"
          element={
            <Home
              token={props.token}
              company={props.company}
              roleLevel={props.roleLevel}
              setHeader={(e) => setDrawerHeaderComponent(e)}
              setInfo={(e) => setDrawerHeaderInfo(e)}
            />
          }
        />

        {props.roleLevel <= 2 && (
          <Route
            path="/products"
            element={
              <Products
                token={props.token}
                company={props.company}
                roleLevel={props.roleLevel}
                setHeader={(e) => setDrawerHeaderComponent(e)}
                setInfo={(e) => setDrawerHeaderInfo(e)}
              />
            }
          />
        )}
        {props.roleLevel <= 2 && (
          <Route
            path="/addProduct"
            element={
              <AddProduct
                token={props.token}
                company={props.company}
                roleLevel={props.roleLevel}
                setHeader={(e) => setDrawerHeaderComponent(e)}
                setInfo={(e) => setDrawerHeaderInfo(e)}
              />
            }
          />
        )}
        {props.roleLevel < 2 && (
          <Route
            path="/users"
            element={
              <Users
                token={props.token}
                company={props.company}
                roleLevel={props.roleLevel}
                setHeader={(e) => setDrawerHeaderComponent(e)}
                setInfo={(e) => setDrawerHeaderInfo(e)}
              />
            }
          />
        )}
        {props.roleLevel < 1 && (
          <Route
            path="/addUser"
            element={
              <AddUsers
                token={props.token}
                company={props.company}
                roleLevel={props.roleLevel}
                setHeader={(e) => setDrawerHeaderComponent(e)}
                setInfo={(e) => setDrawerHeaderInfo(e)}
              />
            }
          />
        )}
        {props.roleLevel <= 2 && (
          <Route
            path="/headings"
            element={
              <Headings
                token={props.token}
                company={props.company}
                roleLevel={props.roleLevel}
                setHeader={(e) => setDrawerHeaderComponent(e)}
                setInfo={(e) => setDrawerHeaderInfo(e)}
              />
            }
          />
        )}
        {props.roleLevel <= 2 && (
          <Route
            path="/templates"
            element={
              <Templates
                token={props.token}
                company={props.company}
                roleLevel={props.roleLevel}
                setHeader={(e) => setDrawerHeaderComponent(e)}
                setInfo={(e) => setDrawerHeaderInfo(e)}
              />
            }
          />
        )}

        {props.roleLevel < 1 && (
          <Route
            path="/productTypes"
            element={
              <ProductTypes
                token={props.token}
                company={props.company}
                roleLevel={props.roleLevel}
                setHeader={(e) => setDrawerHeaderComponent(e)}
                setInfo={(e) => setDrawerHeaderInfo(e)}
              />
            }
          />
        )}
        {props.roleLevel < 2 && (
          <Route
            path="/productCategory"
            element={
              <ProductCategories
                token={props.token}
                company={props.company}
                roleLevel={props.roleLevel}
                setHeader={(e) => setDrawerHeaderComponent(e)}
                setInfo={(e) => setDrawerHeaderInfo(e)}
              />
            }
          />
        )}

        {props.roleLevel <= 2 && (
          <Route
            path="/images"
            element={
              <Images
                token={props.token}
                company={props.company}
                roleLevel={props.roleLevel}
                setHeader={(e) => setDrawerHeaderComponent(e)}
                setInfo={(e) => setDrawerHeaderInfo(e)}
              />
            }
          />
        )}
        {props.roleLevel < 1 && (
          <Route
            path="/companyProfile"
            element={
              <CompanyProfile
                token={props.token}
                company={props.company}
                roleLevel={props.roleLevel}
                setHeader={(e) => setDrawerHeaderComponent(e)}
                setInfo={(e) => setDrawerHeaderInfo(e)}
              />
            }
          />
        )}
        {props.roleLevel < 1 && (
          <Route
            path="/roles"
            element={
              <Roles
                token={props.token}
                company={props.company}
                roleLevel={props.roleLevel}
                setHeader={(e) => setDrawerHeaderComponent(e)}
                setInfo={(e) => setDrawerHeaderInfo(e)}
              />
            }
          />
        )}
      </Routes>
    </Drawer>
  );
}
