import React, { useEffect, useState } from "react";
import styles from "./login.module.css";

import axios from "axios";
import config from "../../config";

import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../../components/SnackbarAlert";
import CustomLink from "../../components/CustomLink";

import { TextField, Box, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import coverImg from "../../Assets/regImg.png";
import bgImg from "../../Assets/regImgRight.png";

export default function Login(props) {
  const [loader, setLoader] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const curve = require("../../Assets/Vector.png");
  const logo = require("../../Assets/caseWellLogo.png");

  useEffect(() => {
    const storedToken = localStorage.getItem("tds_token");
    if (storedToken && storedToken != "null") {
      const url = config.uri + "public/" + config.version + "/decodeToken";
      axios
        .get(url, { params: { token: storedToken } })
        .then((e) => {
          props.setName(e.data.payload.name);
          props.setRole(e.data.payload.role);
          props.setCompany(e.data.payload.company);
          props.setToken(storedToken);
        })
        .catch((err) => {
          localStorage.setItem("tds_token", "null");
        });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => setLoader(false), 1000);
  }, []);

  const handleLogin = async () => {
    setLoading(true);

    if (!email || !password) return showError("email or password.");
    if (email.trim() == "" || password.trim() == "")
      return showError("email or password.");

    const url = config.uri + "public/" + config.version + "/login";

    await axios
      .get(url, {
        params: {
          email: email.toLowerCase(),
          password: encodeURIComponent(password),
        },
      })
      .then((e) => {
        props.setToken(e.data.token);
        props.setName(e.data.name);
        props.setRole(e.data.role);
        props.setCompany(e.data.company);
        setLoading(false);
      })
      .catch((err) => {
        setSnackbarMsg(err.response?.data?.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  const showError = (reason) => {
    setSnackbarMsg("Error: Enter Valid " + reason);
    setSnackbarVariant("error");
    setSnackbarOpen(true);
    return setLoading(false);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1ca0c4",
      },
    },
  });

  return loader ? (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.sectionLeft}>
        <img
          src={coverImg}
          width={600}
          height={600}
          className={styles.coverImg}
        />
        <img className={styles.footer} src={curve} height={150} />
      </div>
      <div
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "100% auto",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
        }}
        className={styles.sectionRight}
      >
        <div className={styles.inputContainer}>
          <div
            style={{
              width: "528px",
              textAlign: "center",
              marginBottom: "60px",
            }}
          >
            <img src={logo} width={180} height={80} className={styles.logo} />
            <h2 style={{ fontWeight: "bold" }}>Welcome To Tenie PDM!!!</h2>
          </div>

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width: "95%",
                display: "flex",
                flexDirection: "column",
              },
            }}
            noValidate
            autoComplete="off"
          >
            <div className={styles.input}>
              <TextField
                InputLabelProps={{
                  style: { fontSize: 20 },
                }}
                InputProps={{
                  style: { backgroundColor: "#f5f5f5", fontSize: 20 },
                }}
                label="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.input}>
              <TextField
                InputLabelProps={{
                  style: { fontSize: 20 },
                }}
                InputProps={{
                  style: { backgroundColor: "#f5f5f5", fontSize: 20 },
                }}
                type="password"
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <ThemeProvider theme={theme}>
              <Button
                onClick={handleLogin}
                className={styles.button}
                variant="contained"
              >
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <b>Sign In</b>
                )}
              </Button>
            </ThemeProvider>
            <div className={styles.textContainer}>
              <CustomLink to="/register">
                <p className={styles.forgotPassTxt}>
                  <span style={{ color: "black" }}>
                    Don't have a Tenie PDM account?
                  </span>{" "}
                  Sign Up
                </p>
              </CustomLink>

              <CustomLink to="/forgotPassword">
                <p className={styles.forgotPassTxt}>Forgot Password</p>
              </CustomLink>
            </div>
          </Box>
        </div>
      </div>
      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
    </div>
  );
}
