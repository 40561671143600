import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";

export default class Template3 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      LogoImage: require("../Assets/caseWellLogo.png"),
      ProductName: "Product Name",
      ProductImage: require("../Assets/placeholder.png"),
      headings: [],
      text: [],
      disclaimer: "",
    };
  }

  async componentDidMount() {
    const urllogo = config.uri + "public/" + config.version + "/templateData";
    await axios
      .get(urllogo, { params: { company: this.props.company } })
      .then((e) => {
        const base64String = btoa(
          new Uint8Array(e.data?.Logo?.data?.data).reduce(function (
            data,
            byte
          ) {
            return data + String.fromCharCode(byte);
          },
          "")
        );

        this.setState({
          disclaimer: e.data?.Disclaimer,
        });

        if (base64String) {
          this.setState({ LogoImage: `data:image/png;base64,${base64String}` });
        }
      });

    if (this.props.productName) {
      this.setState({ ProductName: this.props.productName });
    }

    if (this.props.productImage) {
      this.setState({
        ProductImage: `${config.uri}admin/${config.version}/getFile?company=${this.props.company}&key=${this.props.productImage}`,
      });
    }

    if (this.props.custom == true) {
      let tempCustomHeadings = [];
      let tempCustomText = [];
      for (let i = 0; i < this.props.headings.length; i++) {
        for (let j = 0; j < this.props.customHeadings.length; j++) {
          if (this.props.headings[i] == this.props.customHeadings[j]) {
            tempCustomHeadings.push(this.props.headings[i]);
            tempCustomText.push(this.props.text[i]);
          }
        }
      }
      this.setState({ headings: tempCustomHeadings });
      this.setState({ text: tempCustomText });
    } else {
      if (this.props.headings) this.setState({ headings: this.props.headings });
      if (this.props.text) this.setState({ text: this.props.text });
    }
  }

  render() {
    return (
      <div style={{ padding: "1.1rem 1.8rem" }}>
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "space-between",
            marginBottom: "25px",
          }}
        >
          <h2
            style={{
              lineHeight: "0",
              color: "#1CA0C4",

              marginTop: "35px",
              fontSize: "26px",
              marginLeft: "5px",
            }}
          >
            {this.state.ProductName}
          </h2>
          <img height={60} src={this.state.LogoImage}></img>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginTop: "8px",
            height: "550px",
            border: "1px solid #464646",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: "1",
              justifyContent: "center",
              alignItems: "center",
              background: "linear-gradient(to bottom right, #0b141a, #1d2f39)",
            }}
          >
            <img width={550} height={440} src={this.state.ProductImage}></img>
          </div>
          <div
            style={{
              display: "flex",
              flex: "1",
              padding: "5px 15px",
              backgroundColor: "#464646",
              flexDirection: "column",
              overflowY: "hidden",
              justifyContent: "space-evenly",
            }}
          >
            {this.state.headings &&
              this.state.headings.slice(0, 5).map((x, i) => (
                <div
                  key={x + i}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p
                    style={{
                      color: "white",
                      lineHeight: "26px",
                      fontSize: "26px",
                      fontWeight: "600",
                    }}
                  >
                    <b>{x}</b>
                  </p>
                  <p
                    style={{
                      color: "white",

                      lineHeight: "24px",
                      fontSize: "24px",
                      marginTop: "-5px",
                      marginBottom: "2px",
                    }}
                  >
                    {this.state.text[i]}
                  </p>
                </div>
              ))}
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            padding: "10px ",
            borderRadius: "2px",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            marginTop: "8px",
            height: "358px",
            backgroundColor: "#f4f2f2",
            gap: "8px",
          }}
        >
          {this.state.headings &&
            this.state.headings.slice(5, 14).map((x, i) => (
              <div key={x + i}>
                <p
                  style={{
                    color: "#404040",
                    lineHeight: "26px",
                    fontSize: "26px",
                    fontWeight: "600",
                  }}
                >
                  <b>{x}</b>
                </p>
                <p
                  style={{
                    color: "#404040",

                    lineHeight: "24px",
                    fontSize: "24px",
                    marginTop: "-5px",
                    marginBottom: "2px",
                  }}
                >
                  {this.state.text[5 + i]}
                </p>
              </div>
            ))}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            padding: "8px",
            borderRadius: "2px",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            marginTop: "10px",
            height: "358px",
            backgroundColor: "#7E7D7D",
            gap: "8px",
          }}
        >
          {this.state.headings &&
            this.state.headings.slice(14, 23).map((x, i) => (
              <div key={x + i}>
                <p
                  style={{
                    color: "white",
                    lineHeight: "26px",
                    fontSize: "26px",
                    fontWeight: "600",
                  }}
                >
                  <b>{x}</b>
                </p>
                <p
                  style={{
                    color: "white",

                    lineHeight: "24px",
                    fontSize: "24px",
                    marginTop: "-5px",
                    marginBottom: "2px",
                  }}
                >
                  {this.state.text[14 + i]}
                </p>
              </div>
            ))}
        </div>
        <div
          style={{
            overflowY: "hidden",
            marginTop: "8px",
            paddingLeft: "2px",
            padding: "5px 10px",
            backgroundColor: "#efeeee",
            borderRadius: "2px",
            height: "110px",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          }}
        >
          <p style={{ lineHeight: "0", fontSize: "20px", color: "#1ca0c4" }}>
            <b>Disclaimer:</b>
          </p>
          <p style={{ lineHeight: "16px", fontSize: "15px", color: "#404040" }}>
            {this.state.disclaimer}
          </p>
        </div>
      </div>
    );
  }
}
