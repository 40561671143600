import React, { useState } from "react";
import axios from "axios";
import config from "../../config";

import styles from "./forgotPass.module.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";

import SnackbarAlert from "../../components/SnackbarAlert";
import CustomLink from "../../components/CustomLink";

export default function ForgotPassword() {
  const curve = require("../../Assets/Vector.png");
  const logo = require("../../Assets/caseWellLogo.png");

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const [email, setEmail] = useState("");
  const [userOTP, setUserOTP] = useState("");
  const [actualOTP, setActualOTP] = useState("");

  const [viewPassword1, setViewPassword1] = useState(false);
  const [viewPassword2, setViewPassword2] = useState(false);

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const generateOTP = () => {
    const length = 6;
    const charset = "0123456789";
    let otp = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      otp += charset.charAt(randomIndex);
    }

    return otp;
  };

  const sendOTP = async () => {
    const otp = generateOTP();
    setActualOTP(otp);

    if (!email) {
      setSnackbarMsg("Please enter email first");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
    }

    const url = config.uri + "public/" + config.version + "/sendOTP";

    const params = { email, otp };

    axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("OTP sent to your email successfully");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMsg("This email does not match any of our records");
        setSnackbarVariant("warning");
        setSnackbarOpen(true);
      });
  };

  const isStrongPassword = (password) => {
    // Define regular expressions for each criteria
    const hasUppercase = /[A-Z]/;
    const hasLowercase = /[a-z]/;
    const hasNumber = /[0-9]/;
    const hasSpecialSymbol = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    // Check if all criteria are met
    const isLengthValid = password.length >= 8;
    const hasValidUppercase = hasUppercase.test(password);
    const hasValidLowercase = hasLowercase.test(password);
    const hasValidNumber = hasNumber.test(password);
    const hasValidSpecialSymbol = hasSpecialSymbol.test(password);

    // Return true if all criteria are met, otherwise return false
    return (
      isLengthValid &&
      hasValidUppercase &&
      hasValidLowercase &&
      hasValidNumber &&
      hasValidSpecialSymbol
    );
  };

  const updatePassword = async () => {
    if (actualOTP != userOTP || userOTP.trim() == "") {
      setSnackbarMsg("OTP does not match.");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    if (password1 != password2) {
      setSnackbarMsg("Password and confim password should be same.");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    if (!isStrongPassword(password2)) {
      setSnackbarMsg(
        "Password should have 1 uppercase letter 1 lowercase letter 1 number 1 special symbol and atleast 8 characters long."
      );
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    const params = { password: encodeURIComponent(password1), email: email };
    const url = config.uri + "admin/" + config.version + "/updatePassword";

    axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("Password Updated");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <img src={logo} className={styles.logoImg} alt="Logo" />
        <div className={styles.formContainer}>
          <p className={styles.subHeading}>Forgot Password?</p>

          <div className={styles.passInputContainer}>
            <input
              placeholder="Enter Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className={styles.formInput}
            />
            <KeyboardDoubleArrowRightSharpIcon
              onClick={sendOTP}
              className={styles.icon}
              size="medium"
              sx={{ color: "#1ca0c4" }}
            />
          </div>

          <div className={styles.passInputContainer}>
            <input
              placeholder="Enter OTP Received On Email."
              className={styles.formInput}
              value={userOTP}
              onChange={(e) => setUserOTP(e.target.value)}
            />
          </div>

          <div className={styles.passInputContainer}>
            <input
              placeholder="New Password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              type={viewPassword1 ? "text" : "password"}
              className={styles.formInput}
            />
            {viewPassword1 ? (
              <VisibilityOffIcon
                sx={{ color: "#1ca0c4" }}
                onClick={() => setViewPassword1(!viewPassword1)}
                className={styles.icon}
                size="medium"
              />
            ) : (
              <RemoveRedEyeIcon
                sx={{ color: "#1ca0c4" }}
                onClick={() => setViewPassword1(!viewPassword1)}
                className={styles.icon}
                size="medium"
              />
            )}
          </div>

          <div className={styles.passInputContainer}>
            <input
              placeholder="Confirm New Password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              type={viewPassword2 ? "text" : "password"}
              className={styles.formInput}
            />
            {viewPassword2 ? (
              <VisibilityOffIcon
                sx={{ color: "#1ca0c4" }}
                onClick={() => setViewPassword2(!viewPassword2)}
                className={styles.icon}
                size="medium"
              />
            ) : (
              <RemoveRedEyeIcon
                sx={{ color: "#1ca0c4" }}
                onClick={() => setViewPassword2(!viewPassword2)}
                className={styles.icon}
                size="medium"
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "470px",
            }}
          >
            <button className={styles.btn} onClick={updatePassword}>
              Submit
            </button>
            <CustomLink to="/">
              <button className={styles.btn2}>Cancel</button>
            </CustomLink>
          </div>
        </div>
        <div className={styles.footer}>
          <img
            src={curve}
            className={styles.footerImg}
            alt="footer curve image"
          />
        </div>
      </div>

      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
    </div>
  );
}
