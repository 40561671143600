import React, { useState } from "react";

import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";

import SnackbarAlert from "../../components/SnackbarAlert";
import styles from "./register.module.css";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

import PhoneInput from "react-phone-input-2";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import coverImg from "../../Assets/regImg.png";
import logo from "../../Assets/caseWellLogo.png";
import curve from "../../Assets/Vector.png";
import bgImg from "../../Assets/regImgRight.png";

import { TextField, Box, Paper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomLink from "../../components/CustomLink";

export default function Register() {
  const navigate = useNavigate();

  const [next, setNext] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const availableIndustry = [
    "Information Technology",
    "Automobile",
    "Pharmaceutical",
    "Textile",
    "Telecommunications",
    "Banking",
    "Agriculture",
    "Construction",
    "Tourism",
    "Healthcare",
    "Retail",
    "Media and Entertainment",
    "Energy",
    "Manufacturing",
    "Financial Services",
    "Education",
    "Hospitality",
    "Transportation",
    "Real Estate",
    "Food Processing",
  ];

  const [companyDetails, setCompanyDetails] = useState({
    CompanyName: "",
    Industry: "",
    VAT: "",
    GST: "",
    PAN: "",
    Name: "",
    Email: "",
    Number: "",
    Password: "",
    Country: "",
    Region: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const [agree, setAgree] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1ca0c4",
      },
    },
  });

  const isStrongPassword = (password) => {
    // Define regular expressions for each criteria
    const hasUppercase = /[A-Z]/;
    const hasLowercase = /[a-z]/;
    const hasNumber = /[0-9]/;
    const hasSpecialSymbol = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    // Check if all criteria are met
    const isLengthValid = password.length >= 8;
    const hasValidUppercase = hasUppercase.test(password);
    const hasValidLowercase = hasLowercase.test(password);
    const hasValidNumber = hasNumber.test(password);
    const hasValidSpecialSymbol = hasSpecialSymbol.test(password);

    // Return true if all criteria are met, otherwise return false
    return (
      isLengthValid &&
      hasValidUppercase &&
      hasValidLowercase &&
      hasValidNumber &&
      hasValidSpecialSymbol
    );
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    if (!agree) {
      setSnackbarMsg(
        "Please agree to our terms and conditions to proceed further."
      );
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    if (
      !(
        companyDetails["VAT"] != "" ||
        companyDetails["PAN"] != "" ||
        companyDetails["GST"] != ""
      )
    ) {
      setSnackbarMsg(`Please enter either VAT or GST or PAN info first.`);
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    Object.entries(companyDetails).forEach(([key, value]) => {
      if (!value || value.trim() == "") {
        if (key != "VAT" && key != "GST" && key != "PAN") {
          setSnackbarMsg(`Please enter ${key} first.`);
          setSnackbarVariant("error");
          setSnackbarOpen(true);
          return;
        }
      }
    });

    if (!isStrongPassword(companyDetails.Password)) {
      setSnackbarMsg(
        `Password should have 1 uppercase letter 1 lowercase letter 1 number 1 special symbol and atleast 8 characters long`
      );
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    companyDetails.Password = encodeURIComponent(companyDetails.Password);

    const url = config.uri + "public/" + config.version + "/companyDetails";

    await axios
      .post(url, companyDetails)
      .then((e) => {
        if (e.data.message == "error") {
          setSnackbarMsg(e.data.reason);
          setSnackbarVariant("error");
          setSnackbarOpen(true);
        } else {
          setSnackbarMsg(`Created a new company profile successfully!`);
          setSnackbarVariant("success");
          setSnackbarOpen(true);
          setOpenDialog(true);
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.sectionLeft}>
        <img src={logo} width={200} height={80} className={styles.logo} />
        <img
          src={coverImg}
          width={600}
          height={600}
          className={styles.coverImg}
        />
        <img src={curve} width={"100%"} height={150} />
      </div>
      <div
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "100% auto",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
        }}
        className={styles.sectionRight}
      >
        <div className={styles.textContainer}>
          {next ? (
            <span
              id="back"
              className={styles.spanTxt}
              onClick={() => setNext(false)}
            >
              Back
            </span>
          ) : (
            <>
              Already have a Tenie PDM account?{" "}
              <CustomLink to="/" id="login">
                <span className={styles.spanTxt}>Sign In</span>
              </CustomLink>
            </>
          )}
        </div>

        {!next ? (
          <div className={styles.inputContainer}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 1,
                  width: "95%",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <div className={styles.input}>
                <TextField
                  InputLabelProps={{
                    style: { fontSize: 20 },
                  }}
                  InputProps={{
                    style: { backgroundColor: "#f5f5f5", fontSize: 20 },
                  }}
                  label="Company Name"
                  value={companyDetails.CompanyName}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      CompanyName: e.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.input}>
                <FormControl
                  style={{
                    marginLeft: "8px",
                    width: "523px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: 20,
                      marginTop: -0.5,
                    }}
                    id="selectIndustry"
                  >
                    Select Industry
                  </InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: { backgroundColor: "#f5f5f5", maxHeight: 400 },
                      },
                    }}
                    id="selectComponent"
                    labelId="selectIndustry"
                    value={companyDetails.Industry}
                    label="Select Industry"
                    onChange={(e) =>
                      setCompanyDetails({
                        ...companyDetails,
                        Industry: e.target.value,
                      })
                    }
                  >
                    {availableIndustry.map((x, i) => (
                      <MenuItem value={x}>{x}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles.input}>
                <TextField
                  InputLabelProps={{
                    style: { fontSize: 20 },
                  }}
                  InputProps={{
                    style: { backgroundColor: "#f5f5f5", fontSize: 20 },
                  }}
                  label="VAT Number"
                  value={companyDetails.VAT}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      VAT: e.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.input}>
                <TextField
                  InputLabelProps={{
                    style: { fontSize: 20 },
                  }}
                  InputProps={{
                    style: { backgroundColor: "#f5f5f5", fontSize: 20 },
                  }}
                  label="GST Number"
                  value={companyDetails.GST}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      GST: e.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.input}>
                <TextField
                  InputLabelProps={{
                    style: { fontSize: 20 },
                  }}
                  InputProps={{
                    style: { backgroundColor: "#f5f5f5", fontSize: 20 },
                  }}
                  label="PAN Number"
                  value={companyDetails.PAN}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      PAN: e.target.value,
                    })
                  }
                />
              </div>
              <ThemeProvider theme={theme}>
                <Button
                  onClick={() => setNext(true)}
                  className={styles.button}
                  variant="contained"
                >
                  NEXT
                </Button>
              </ThemeProvider>
            </Box>
          </div>
        ) : (
          <div className={styles.inputContainer}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 1,
                  width: "95%",
                  display: "flex",
                  flexDirection: "column",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <div className={styles.input}>
                <TextField
                  InputLabelProps={{
                    style: { fontSize: 20 },
                  }}
                  InputProps={{
                    style: { backgroundColor: "#f5f5f5", fontSize: 20 },
                  }}
                  label="Full Name"
                  value={companyDetails.Name}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      Name: e.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.input}>
                <TextField
                  InputLabelProps={{
                    style: { fontSize: 20 },
                  }}
                  InputProps={{
                    style: { backgroundColor: "#f5f5f5", fontSize: 20 },
                  }}
                  label="Email Address"
                  value={companyDetails.Email}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      Email: e.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.input}>
                <PhoneInput
                  inputStyle={{ backgroundColor: "#f5f5f5", fontSize: 20 }}
                  containerStyle={{
                    marginLeft: "8px",
                    width: "500px !important",
                  }}
                  country={"in"}
                  value={companyDetails.Number}
                  onChange={(phone) =>
                    setCompanyDetails({ ...companyDetails, Number: phone })
                  }
                />
              </div>
              <div className={styles.input}>
                <FormControl sx={{ width: "68.7ch" }} variant="filled">
                  <TextField
                    InputLabelProps={{
                      style: { fontSize: 20 },
                    }}
                    InputProps={{
                      style: { backgroundColor: "#f5f5f5", fontSize: 20 },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    value={companyDetails.Password}
                    onChange={(e) =>
                      setCompanyDetails({
                        ...companyDetails,
                        Password: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </div>
              <div className={styles.input}>
                <CountryDropdown
                  value={companyDetails.Country}
                  onChange={(e) =>
                    setCompanyDetails({ ...companyDetails, Country: e })
                  }
                  style={{
                    width: "200px",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    backgroundColor: "#f5f5f5",
                    fontSize: "20px",
                    outline: "none",
                    transition: "border-color 0.2s ease-in-out",
                    marginLeft: "8px",
                    width: "523px",
                    height: "56px",
                    "&:focus": {
                      borderColor: "#80bdff",
                      boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                    },
                  }}
                />
              </div>
              <div className={styles.input}>
                <RegionDropdown
                  style={{
                    width: "200px",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    backgroundColor: "#f5f5f5",
                    fontSize: "20px",
                    outline: "none",
                    transition: "border-color 0.2s ease-in-out",
                    marginLeft: "8px",
                    width: "522.5px",
                    height: "56px",
                    "&:focus": {
                      borderColor: "#80bdff",
                      boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                    },
                  }}
                  onChange={(e) =>
                    setCompanyDetails({ ...companyDetails, Region: e })
                  }
                  country={companyDetails.Country}
                  value={companyDetails.Region}
                />
              </div>
              <div className={styles.tick}>
                <label htmlFor="termsCheckbox" className="tickButton">
                  <input
                    type="checkbox"
                    checked={agree}
                    onClick={() => setAgree(!agree)}
                  />
                  I agree to the{" "}
                  <a target="_blank" href="https://tenie.co/privacyPolicy">
                    <b style={{ cursor: "pointer" }}>
                      <u>Privacy Policy and other T&C</u>
                    </b>
                  </a>
                </label>
              </div>

              <ThemeProvider theme={theme}>
                <Button
                  className={styles.button}
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ fontWeight: "bold" }}
                >
                  Create Account
                </Button>
              </ThemeProvider>
            </Box>
          </div>
        )}
      </div>
      <div>
        <Dialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Paper sx={{ border: "5px solid #1ca0c4", borderRadius: "3px" }}>
            <DialogTitle sx={{ fontSize: "24px" }} id="alert-dialog-title">
              {"Congratulations"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{ fontSize: "19px" }}
                id="alert-dialog-description"
              >
                Welcome to Tenie PDM. You have successfully opened Tenie PDM
                account for your company - {companyDetails.CompanyName}. We have
                sent you an email at {companyDetails.Email} with your login
                credentials. Kindly check your inbox including Spam and Junk
                folders.
                <br />
                We are confident that you would enjoy using Tenie PDM. In the
                event you face any issues, don't hesitate to write to
                <br />
                <i>support@tenie.co.</i>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  navigate("/");
                }}
              >
                Okay
              </Button>
            </DialogActions>
          </Paper>
        </Dialog>
      </div>
      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
    </div>
  );
}
