import React, { useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import ReactToPrint from "react-to-print";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";

import { createPopper } from "@popperjs/core";

import * as XLSX from "xlsx";

import CustomLink from "../../components/CustomLink";
import SnackbarAlert from "../../components/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";
import CircularProgressWithPercentage from "../../components/CircularProgressWithPercentage";
import DeleteAlert from "../../components/DeleteAlert";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import styles from "./products.module.css";
import Template1 from "../../components/Template1";
import Template2 from "../../components/Template2";
import Template3 from "../../components/Template3";
import Template4 from "../../components/Template4";
import Template5 from "../../components/Template5";
import Template6 from "../../components/Template6";
import Template7 from "../../components/Template7";

export default function Products(props) {
  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  }, []);

  const fileInputRef = useRef(null);
  const attachmentFileRef = useRef(null);

  const navigate = useNavigate();

  const [Templates, setTemplates] = useState([
    "Template1",
    "Template2",
    "Template3",
    "Template4",
    "Template5",
    "Template6",
    "Template7",
  ]);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(null);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("productName");
  const [sortType, setSortType] = useState("desc");

  //for delete alert
  const [deleteItem, setDeleteItem] = useState(null);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  //For Attachments
  const [openAttachModal, setOpenAttachModal] = useState(false);
  const [newAttachment, setNewAttachment] = useState("");
  const [attachBtnLoading, setAttachBtnLoading] = useState(false);
  const [currentProductData, setCurrentProductData] = useState(null);

  const [search, setSearch] = useState("");

  const [headings, setHeadings] = useState(null);
  const [images, setImages] = useState(null);

  const [validRow, setValidRow] = useState([]);

  const [customTemplates, setCustomTemplates] = useState(null);

  //To set different templates for each row of products
  const [rowTemplates, setRowTemplates] = useState([]);
  //To set different images for each row of products
  const [rowImage, setRowImage] = useState([]);

  const [openImportDataDialog, setOpenImportDataDialog] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const addIcon = require("../../Assets/addUserIcon.png");
  const deleteIcon = require("../../Assets/delete.png");
  const downloadIcon = require("../../Assets/downloadIcon.png");
  const sortAsc = require("../../Assets/sortAsc.png");
  const sortDesc = require("../../Assets/sortDesc.png");

  const fetchData = async () => {
    const url = config.uri + "admin/" + config.version + "/product";
    axios
      .get(url, {
        params: {
          company: props.company,
          search: search,
          sort: sortBy,
          page: currentPage,
          sortType: sortType,
        },
      })
      .then((e) => {
        {
          const products = e.data?.products || null;
          setData(products);
          setPages(Math.ceil(e.data?.total / 25));
          setLoading(false);
          for (let i = 0; i < products?.length; i++) {
            if (
              !products[i].template ||
              products[i].template.trim() == "" ||
              !products[i].imageName ||
              products[i].imageName.trim() == ""
            ) {
              setValidRow((prev) => {
                const updated = [...prev];
                updated[i] = false;
                return updated;
              });
            } else {
              setValidRow((prev) => {
                const updated = [...prev];
                updated[i] = true;
                return updated;
              });
            }
          }
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [sortBy, currentPage, sortType]);

  useEffect(() => {
    setCurrentPage(1);
    fetchData();
  }, [search]);

  const fetchHeadings = async () => {
    const url = config.uri + "admin/" + config.version + "/headings";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setHeadings(e.data);
      })
      .catch((err) => {
        setSnackbarMsg("Error in fetching headings");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const fetchImages = async () => {
    const url = config.uri + "admin/" + config.version + "/imageNameList";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setImages(e.data);
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const fetchTemplates = async () => {
    const url = config.uri + "admin/" + config.version + "/templates";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setCustomTemplates(e.data);
        for (let i = 0; i < e.data.length; i++) {
          setTemplates((prev) => [...prev, e.data[i].name]);
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    fetchHeadings();
    fetchImages();
    fetchTemplates();
  }, []);

  useEffect(() => {
    props.setHeader(() => (
      <CustomLink to="/home/addProduct">
        <div
          style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
        >
          <img
            src={addIcon}
            width="28px"
            height="28px"
            style={{ marginRight: "14px" }}
          />
          <Typography variant="h5" noWrap component="div">
            Add Product
          </Typography>
        </div>
      </CustomLink>
    ));
    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  const handleExcelDownload = async () => {
    let columns = [];
    let rowsData = [];

    const url = config.uri + "admin/" + config.version + "/exportData";

    let exportData = await axios.get(url, {
      params: { company: props.company },
    });

    let data = exportData?.data?.data;

    columns[0] = { Header: "Product Name", accessor: "productName" };
    columns[1] = { Headers: "Product Category", accessor: "productCategory" };
    columns[2] = { Headers: "Product Code", accessor: "productCode" };
    columns[3] = { Headers: "Product Description", accessor: "desc" };
    columns[4] = { Headers: "Attachments", accessor: "attachments" };
    columns[5] = { Headers: "Files", accessor: "Files" };

    for (let i = 0; i < data.length; i++) {
      let tempRowData = {};

      tempRowData["Product Name"] = data[i].productName;
      tempRowData["Product Category"] = data[i].productCategory;
      tempRowData["Product Code"] = data[i].productCode;
      tempRowData["Product Description"] = data[i].desc;
      tempRowData["Attachments"] = data[i].attachments?.length || 0;
      tempRowData["Files"] = data[i].attachments?.join(", ") || "";

      // Calculate total file size for each row
      const rowFiles = data[i].attachments || [];
      const totalFileSize = rowFiles.reduce((total, fileName) => {
        const sizeObj = exportData.data.files.find(
          (fileObj) => fileObj[fileName] !== undefined
        );
        if (sizeObj) {
          return total + sizeObj[fileName];
        }
        return total;
      }, 0);
      tempRowData["Total File Size"] = totalFileSize + " KB";

      for (let j = 0; j < headings.length; j++) {
        let currentHeading = headings[j].name;
        let dataFound = false;
        for (let k = 0; k < data[i].headings.length; k++) {
          if (currentHeading == data[i].headings[k]) {
            tempRowData[currentHeading] = data[i].text[k];
            dataFound = true;
          }
        }
        if (!dataFound) {
          tempRowData[currentHeading] = "";
        }
      }
      rowsData.push(tempRowData);
    }

    for (let i = 0; i < headings.length; i++) {
      let currentHeading = headings[i].name;
      let tempHeading = { Header: currentHeading, accessor: currentHeading };
      columns.push(tempHeading);
    }

    const worksheet = XLSX.utils.json_to_sheet(rowsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
      type: "buffer",
      bookType: "xlsx",
    });
    saveExcelFile(
      excelBuffer,
      `Tenie PDM Product Data - ${props.company} ` + new Date() + ".xlsx"
    );
  };

  const saveExcelFile = (buffer, filename) => {
    const data = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // IE workaround
      window.navigator.msSaveBlob(data, filename);
    } else {
      // Other browsers
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const uploadNewHeading = async (inp) => {
    if (!inp || inp.trim == "") {
      setSnackbarMsg("Not a valid heading: ", inp);
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    const url = config.uri + "admin/" + config.version + "/heading";
    const params = { name: inp?.trim(), company: props.company };

    await axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("Success");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
      })
      .catch((err) => {
        setSnackbarMsg(err.response.data || "error");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const handleXlsxUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (jsonData[0][0].trim() != "Product Name") {
        setSnackbarMsg("First Column should be Product Name");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        return;
      }

      if (jsonData[0][1].trim() != "Product Category") {
        setSnackbarMsg("Second Column should be Product Category");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        return;
      }

      if (jsonData[0][2].trim() != "Product Code") {
        setSnackbarMsg("Third Column should be Product Code");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        return;
      }

      if (jsonData[0][3].trim() != "Product Description") {
        setSnackbarMsg("Fourth Column should be Product Description");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        return;
      }

      if (jsonData.length <= 1) {
        setSnackbarMsg("Error: Not a valid file/data");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        return;
      }

      //if a product category is not in the database then insert it
      for (let i = 1; i < jsonData.length; i++) {
        if (jsonData[i][1]?.trim() == "") {
          setSnackbarMsg(`Product Category is empty for product ${i}`);
          setSnackbarVariant("error");
          setSnackbarOpen(true);
          return;
        }
        if (jsonData[i - 1][1] != jsonData[i][1]) {
          const categoryToBeAdded = jsonData[i][1];
          const url =
            config.uri + "admin/" + config.version + "/product_category";
          const params = {
            productCategory: categoryToBeAdded?.trim(),
            company: props.company,
          };

          await axios
            .post(url, params)
            .then((e) => {
              setSnackbarMsg(`Product Category ${categoryToBeAdded} Added.`);
              setSnackbarVariant("success");
              setSnackbarOpen(true);
            })
            .catch((err) => {
              setSnackbarMsg(err.response.data || "error");
              setSnackbarVariant("error");
              setSnackbarOpen(true);
            });
        }
      }
      for (let i = 0; i < jsonData[0].length; i++) {
        let found = false;
        for (let j = 0; j < headings.length; j++) {
          if (headings[j].name?.trim() == jsonData[0][i]?.trim()) {
            found = true;
          }
        }

        // If current heading is not in the database then insert it.
        if (!found) {
          uploadNewHeading(jsonData[0][i]);
        }
      }

      for (let i = 1; i < jsonData.length; i++) {
        let selectedHeadings = [];
        let textData = [];
        for (let j = 4; j < jsonData[i].length; j++) {
          if (jsonData[i][j]) {
            selectedHeadings.push(jsonData[0][j]?.trim());
            textData.push(jsonData[i][j]);
          }
        }

        //First col is Product Name
        //Second col is Product Category
        //Third col is Product Code
        //Fourth col is Product description

        const params = {
          category: jsonData[i][1],
          code: jsonData[i][2],
          template: "",
          name: jsonData[i][0]?.trim(),
          imageName: "",
          headings: selectedHeadings,
          text: textData,
          desc: jsonData[i][3],
          company: props.company,
        };

        const url = config.uri + "admin/" + config.version + "/product";

        axios
          .post(url, params)
          .then((e) => {
            setSnackbarMsg("Successfully created a new product!");
            setSnackbarVariant("success");
            setSnackbarOpen(true);
            fetchData();
          })
          .catch((err) => {
            setSnackbarMsg(err.response.data || "error");
            setSnackbarVariant("error");
            setSnackbarOpen(true);
            return;
          });
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleDelete = async (x) => {
    setDeleteItem(x);
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    const x = deleteItem;
    const url = config.uri + "admin/" + config.version + "/deleteProduct";
    const params = { id: x, company: props.company };
    try {
      await axios.post(url, params);
      setSnackbarMsg("Deleted Product Successfully");
      setSnackbarVariant("success");
      setSnackbarOpen(true);
      setDeleteItem(null);
      fetchData();
    } catch (error) {
      setDeleteItem(null);
      setSnackbarMsg("Failed to delete product");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
    }
    setIsDeleteAlertOpen(false);
  };

  const handleTemplateChange = (e, i) => {
    setRowTemplates((prev) => {
      const updated = [...prev];
      updated[i] = e.target.value;
      return updated;
    });
  };

  const handleImageChange = (e, i) => {
    setRowImage((prev) => {
      const updated = [...prev];
      updated[i] = e.target.value;
      return updated;
    });
  };

  const handleUpdateProduct = async (id, i) => {
    if (!rowImage[i] || !rowTemplates[i]) {
      setSnackbarMsg("Please select valid image and template first");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    if (rowImage[i].trim() == "" || rowTemplates[i].trim() == "") {
      setSnackbarMsg("Please select valid image and template first");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    const url = config.uri + "admin/" + config.version + "/updateProduct";

    const params = {
      id,
      image: rowImage[i],
      template: rowTemplates[i],
      company: props.company,
    };

    await axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("Updated product image and template");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        fetchData();
      })
      .catch((err) => {
        setSnackbarMsg("Unable to update product");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setNewAttachment(file);
  };

  const handleAttachmentSubmit = async () => {
    setAttachBtnLoading(true);
    if (currentProductData?.attachments?.length >= 5) {
      setSnackbarMsg("Max 5 attachments are allowed");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      setAttachBtnLoading(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", newAttachment);
      formData.append("productId", currentProductData?._id || "");
      formData.append("company", props.company);

      const url = config.uri + "admin/" + config.version + "/addAttachments";

      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(parseInt(percentage));
        },
      });

      setOpenAttachModal(false);
      fetchData();
    } catch (error) {
      setSnackbarMsg("File upload failed:", error.message);
      setSnackbarVariant("error");
      setSnackbarOpen(true);
    } finally {
      setAttachBtnLoading(false);
      setUploadProgress(0);
      setNewAttachment("");
    }
  };

  const deleteAttachment = async (attachment) => {
    if (props.roleLevel >= 1) {
      setSnackbarMsg("You don't have rights to delete attachments.");
      setSnackbarVariant("success");
      setSnackbarOpen(true);
      return;
    }

    const url = config.uri + "admin/" + config.version + "/deleteAttachment";

    const params = {
      productId: currentProductData?._id,
      attachment: attachment,
      company: props.company,
    };

    await axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("File Deleted Successfully");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        setOpenAttachModal(false);
        fetchData();
      })
      .catch((e) => {
        setSnackbarMsg("Error in deleting fild");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress style={{ color: "#1CA0C4" }} />
        </div>
      ) : (
        <div className={styles.main}>
          <div className={styles.headerRow}>
            <div>
              <FormControl
                sx={{ mb: 1, mt: 1, width: "600px" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Code, Category, Name
                </InputLabel>
                <OutlinedInput
                  value={search}
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Search" edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Code, Category, Name"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={styles.headerBtnContainer}>
              {props.roleLevel < 2 && (
                <Button
                  className={styles.uploadBtn}
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                  onClick={() => setOpenImportDataDialog(true)}
                >
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleXlsxUpload}
                  />
                  Import Data
                </Button>
              )}
              {props.roleLevel < 1 && (
                <Button
                  className={styles.downloadBtn}
                  variant="outlined"
                  startIcon={<CloudDownloadIcon />}
                  onClick={handleExcelDownload}
                >
                  Export Data
                </Button>
              )}
            </div>
          </div>
          <table cellSpacing="0">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>
                  <span>Product Type</span>
                </th>
                <th>
                  <span>Product Category</span>

                  {sortType == "asc" && sortBy == "productCategory" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productCategory");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}

                  {!(sortType == "asc" && sortBy == "productCategory") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productCategory");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th>
                  <span>Product Name</span>
                  {sortType == "asc" && sortBy == "productName" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productName");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                  {!(sortType == "asc" && sortBy == "productName") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productName");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th>
                  <span>Product Code</span>
                  {sortType == "asc" && sortBy == "productCode" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productCode");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                  {!(sortType == "asc" && sortBy == "productCode") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productCode");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>

                <th>Image Used</th>
                <th>Template Used</th>
                <th style={{ textAlign: "center", width: "170px" }}>
                  <span>Attachments</span>
                  {sortType == "asc" && sortBy == "attachments" && (
                    <img
                      onClick={() => {
                        setSortBy("attachments");
                        setSortType((prevSortType) =>
                          prevSortType == "asc" ? "desc" : "asc"
                        );
                      }}
                      src={sortAsc}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      width={"20px"}
                      height={"20px"}
                    />
                  )}
                  {!(sortType == "asc" && sortBy == "attachments") && (
                    <img
                      onClick={() => {
                        setSortBy("attachments");
                        setSortType((prevSortType) =>
                          prevSortType == "asc" ? "desc" : "asc"
                        );
                      }}
                      src={sortDesc}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      width={"20px"}
                      height={"20px"}
                    />
                  )}
                </th>
                {props.roleLevel < 2 && (
                  <th style={{ textAlign: "center" }}>Edit</th>
                )}
                <th style={{ textAlign: "center" }}>Save</th>
                <th style={{ textAlign: "center" }}>View</th>
                {props.roleLevel < 2 && (
                  <th style={{ textAlign: "center" }}>Delete</th>
                )}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((x, i) => {
                  let componentRef = React.createRef();

                  return (
                    <tr key={`key${i}`}>
                      <td style={{ display: "none" }}>
                        {(rowTemplates[i] == "Template7" ||
                          x.template == "Template7") && (
                          <Template7
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}
                        {(rowTemplates[i] == "Template6" ||
                          x.template == "Template6") && (
                          <Template6
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}

                        {(rowTemplates[i] == "Template5" ||
                          x.template == "Template5") && (
                          <Template5
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}
                        {(rowTemplates[i] == "Template4" ||
                          x.template == "Template4") && (
                          <Template4
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}
                        {(rowTemplates[i] == "Template3" ||
                          x.template == "Template3") && (
                          <Template3
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}
                        {(rowTemplates[i] == "Template2" ||
                          x.template == "Template2") && (
                          <Template2
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}
                        {(rowTemplates[i] == "Template1" ||
                          x.template == "Template1") && (
                          <Template1
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}

                        {(() => {
                          for (let m = 0; m < customTemplates?.length; m++) {
                            if (
                              customTemplates[m].name == rowTemplates[i] ||
                              x.template == customTemplates[m].name
                            ) {
                              if (
                                customTemplates[m].derivedFrom == "Template1"
                              ) {
                                return (
                                  <Template1
                                    custom={true}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    customHeadings={customTemplates[m].headings}
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              if (
                                customTemplates[m].derivedFrom == "Template2"
                              ) {
                                return (
                                  <Template2
                                    custom={true}
                                    customHeadings={customTemplates[m].headings}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              if (
                                customTemplates[m].derivedFrom == "Template3"
                              ) {
                                return (
                                  <Template3
                                    custom={true}
                                    customHeadings={customTemplates[m].headings}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              if (
                                customTemplates[m].derivedFrom == "Template4"
                              ) {
                                return (
                                  <Template4
                                    custom={true}
                                    customHeadings={customTemplates[m].headings}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              if (
                                customTemplates[m].derivedFrom == "Template5"
                              ) {
                                return (
                                  <Template5
                                    custom={true}
                                    customHeadings={customTemplates[m].headings}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              if (
                                customTemplates[m].derivedFrom == "Template6"
                              ) {
                                return (
                                  <Template6
                                    custom={true}
                                    customHeadings={customTemplates[m].headings}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              if (
                                customTemplates[m].derivedFrom == "Template7"
                              ) {
                                return (
                                  <Template7
                                    custom={true}
                                    customHeadings={customTemplates[m].headings}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              return null;
                            }
                          }
                        })()}
                      </td>
                      <td id="sno">{(currentPage - 1) * 25 + (i + 1)}</td>
                      <td>{x.productType}</td>
                      <td>{x.productCategory}</td>
                      <td>{x.productName}</td>
                      <td>{x.productCode}</td>
                      <td>
                        {x.imageName && x.imageName.trim() != "" ? (
                          x.imageName
                        ) : (
                          <FormControl fullWidth>
                            <InputLabel id="selectImage">
                              Select Image
                            </InputLabel>
                            <Select
                              MenuProps={{ style: { maxHeight: 400 } }}
                              labelId="selectImage"
                              value={rowImage[i] || ""}
                              label="Image Name"
                              onChange={(e) => handleImageChange(e, i)}
                            >
                              <MenuItem value="">
                                <em>Select a image</em>
                              </MenuItem>
                              {images &&
                                images.map((x, i) => (
                                  <MenuItem key={x + i} value={x}>
                                    {x}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )}
                      </td>
                      <td>
                        {x.template && x.template.trim() != ""
                          ? x.template
                          : (x.imageName || rowImage[i]) && (
                              <FormControl style={{ minWidth: "180px" }}>
                                <InputLabel id="selectTemplate">
                                  Select Template
                                </InputLabel>
                                <Select
                                  MenuProps={{ style: { maxHeight: 400 } }}
                                  labelId="selectTemplate"
                                  value={rowTemplates[i] || ""}
                                  label="Select Template "
                                  onChange={(e) => handleTemplateChange(e, i)}
                                >
                                  <MenuItem value="">
                                    <em>Select a template</em>
                                  </MenuItem>
                                  {Templates.map((x, i) => (
                                    <MenuItem key={x + i} value={x}>
                                      {x}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                      </td>
                      <td
                        className="attachment-cell"
                        onClick={() => {
                          setCurrentProductData(x);
                          setOpenAttachModal(true);
                        }}
                      >
                        <div
                          className="attachment-count"
                          data-attachments={
                            x.attachments?.length > 0
                              ? x.attachments
                                  ?.map((attachment) => attachment)
                                  .join(",\n")
                              : "No Attachments Found"
                          }
                        >
                          {x.attachments?.length || 0}
                        </div>
                      </td>

                      {props.roleLevel < 2 && (
                        <td style={{ textAlign: "center" }}>
                          {validRow[i] && (
                            <EditNoteOutlinedIcon
                              onClick={() =>
                                navigate("/home/addProduct", {
                                  state: { product: x },
                                })
                              }
                              sx={{ cursor: "pointer", color: "#1ca0c4" }}
                            />
                          )}
                        </td>
                      )}
                      <td style={{ textAlign: "center" }}>
                        {!validRow[i] && (
                          <DownloadDoneOutlinedIcon
                            onClick={() => handleUpdateProduct(x._id, i)}
                            sx={{ cursor: "pointer", color: "#1ca0c4" }}
                          />
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {validRow[i] && (
                          <ReactToPrint
                            trigger={() => (
                              <VisibilityOutlinedIcon
                                sx={{ cursor: "pointer", color: "#1ca0c4" }}
                              />
                            )}
                            documentTitle={x.productName}
                            content={() => componentRef}
                          />
                        )}
                      </td>
                      {props.roleLevel < 2 && (
                        <td style={{ textAlign: "center" }}>
                          <DeleteForeverOutlinedIcon
                            onClick={() => handleDelete(x._id)}
                            sx={{ cursor: "pointer", color: "#1ca0c4" }}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className={styles.pagination}>
            <span>
              <ArrowBackIosIcon
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
                onClick={() => {
                  if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1);
                }}
              />
            </span>
            <b>{`${currentPage} of ${pages}`}</b>
            <span>
              <ArrowForwardIosIcon
                onClick={() => {
                  if (currentPage + 1 <= pages) setCurrentPage(currentPage + 1);
                }}
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
              />
            </span>
          </div>
        </div>
      )}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openAttachModal}
        onClose={() => setOpenAttachModal(false)}
      >
        <DialogTitle
          sx={{
            fontSize: "25px",
            backgroundColor: "#1ca0c4",
            color: "white",
            marginBottom: "20px",
          }}
        >
          Attachments
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "18px" }}>
            Max of 5 attachments can be added per product. Max file size
            supported is 100MB per attachment
          </DialogContentText>
          <input
            type="file"
            ref={attachmentFileRef}
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />

          {currentProductData?.attachments &&
            currentProductData.attachments.map((x, i) => (
              <div
                key={x + i}
                style={{
                  marginTop: "10px",
                  border: "2px solid #1ca0c4",
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  height: "50px",
                }}
              >
                <div
                  style={{
                    flex: 2,
                    borderRight: "1px solid #1ca0c4",
                  }}
                >
                  {x}
                </div>
                <div
                  style={{
                    flex: 1,
                    borderRight: "1px solid #1ca0c4",
                  }}
                >
                  <img
                    src={downloadIcon}
                    onClick={() => {
                      if (props.roleLevel >= 2) {
                        setSnackbarMsg(
                          "You don't have rights to download attachments."
                        );
                        setSnackbarVariant("success");
                        setSnackbarOpen(true);
                        return;
                      } else {
                        window.open(
                          `${config.uri}admin/${config.version}/getFile?company=${props.company}&key=${x}`,
                          x
                        );
                      }
                    }}
                    width="28px"
                    height="28px"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    borderRight: "1px solid #1ca0c4",
                  }}
                >
                  <img
                    src={deleteIcon}
                    width="28px"
                    height="28px"
                    onClick={() => deleteAttachment(x)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            ))}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 24px",
            marginBottom: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#1ca0c4",
                color: "white",
                height: "50px",
                "&:hover": {
                  backgroundColor: "#147791",
                },
              }}
              onClick={() => attachmentFileRef.current.click()}
              variant="contained"
            >
              Add Attachment
            </Button>
            <Typography sx={{ marginRight: "10px" }}>
              {newAttachment.name || ""}
            </Typography>
          </div>

          <div>
            <Button
              sx={{
                backgroundColor: "#1ca0c4",
                height: "50px",
                color: "white",
                "&:hover": {
                  backgroundColor: "#147791",
                },
              }}
              onClick={handleAttachmentSubmit}
              variant="contained"
            >
              {attachBtnLoading ? (
                <CircularProgressWithPercentage
                  size={"1.3rem"}
                  color="inherit"
                  progress={uploadProgress}
                />
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              sx={{
                backgroundColor: "black",
                color: "white",
                height: "50px",
                marginLeft: "10px",
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
              disabled={attachBtnLoading}
              variant="contained"
              onClick={() => setOpenAttachModal(false)}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
      {/* Import Data Dialog */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openImportDataDialog}
        onClose={() => setOpenImportDataDialog(false)}
        scroll="paper"
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ backgroundColor: "#1ca0c4", color: "#fff" }}
        >
          Note
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <div style={{ fontSize: "18px" }}>
              <ul>
                <li>
                  The first column of data sheet should be named exactly
                  "Product Name".
                </li>
                <li>
                  After that the second column of data sheet should be named
                  exactly "Product Category".
                </li>
                <li>
                  Third column of data sheet should be named exactly "Product
                  Code".
                </li>
                <li>
                  Fourth column of data sheet should be named exactly "Product
                  Description".
                </li>
                <li>
                  After that you can add as many headings for each row of
                  product as you want.
                </li>
              </ul>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button
            sx={{
              backgroundColor: "#1ca0c4",
              color: "white",
              "&:hover": {
                backgroundColor: "#147791",
              },
            }}
            onClick={() => {
              fileInputRef.current.click();
              setOpenImportDataDialog(false);
            }}
          >
            Proceed
          </Button>
          <Button
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
            color="error"
            onClick={() => setOpenImportDataDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteAlert
        open={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        onDelete={handleDeleteConfirmed}
      />
    </div>
  );
}
