import React from "react";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function UserAlert(props) {
  // TODO: Change font size and position to center

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    props.openSnackbar(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={props.open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert
        sx={{ width: "400px", height: "80px", fontSize: "22px" }}
        onClose={handleClose}
        severity={props.variant || "warning"}
      >
        {props.msg}
      </Alert>
    </Snackbar>
  );
}
