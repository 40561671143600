import React, { useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import ReactToPrint from "react-to-print";
import axios from "axios";
import config from "../../config";

import SnackbarAlert from "../../components/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";

import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import {
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import styles from "./home.module.css";

import Template1 from "../../components/Template1";
import Template2 from "../../components/Template2";
import Template3 from "../../components/Template3";
import Template4 from "../../components/Template4";
import Template5 from "../../components/Template5";

export default function Products(props) {
  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  }, []);

  const sortAsc = require("../../Assets/sortAsc.png");
  const sortDesc = require("../../Assets/sortDesc.png");

  const [Templates, setTemplates] = useState([
    "Template1",
    "Template2",
    "Template3",
    "Template4",
    "Template5",
  ]);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(null);

  //For searching, sorting and pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("productName");
  const [sortType, setSortType] = useState("desc");
  const [search, setSearch] = useState("");

  const [headings, setHeadings] = useState(null);
  const [images, setImages] = useState(null);

  const [validRow, setValidRow] = useState([]);

  const [customTemplates, setCustomTemplates] = useState(null);

  //To set different templates for each row of products
  const [rowTemplates, setRowTemplates] = useState([]);
  //To set different images for each row of products
  const [rowImage, setRowImage] = useState([]);

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const fetchData = async () => {
    const url = config.uri + "admin/" + config.version + "/product";
    axios
      .get(url, {
        params: {
          company: props.company,
          for: "home",
          search: search,
          sort: sortBy,
          page: currentPage,
          sortType: sortType,
        },
      })
      .then((e) => {
        {
          const products = e.data?.products || null;
          setData(products);
          setPages(Math.ceil(e.data?.total / 25));
          setLoading(false);
          for (let i = 0; i < products?.length; i++) {
            if (
              !products[i].template ||
              products[i].template.trim() == "" ||
              !products[i].imageName ||
              products[i].imageName.trim() == ""
            ) {
              setValidRow((prev) => {
                const updated = [...prev];
                updated[i] = false;
                return updated;
              });
            } else {
              setValidRow((prev) => {
                const updated = [...prev];
                updated[i] = true;
                return updated;
              });
            }
          }
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [sortBy, currentPage, sortType]);

  useEffect(() => {
    setCurrentPage(1);
    fetchData();
  }, [search]);

  const fetchHeadings = async () => {
    const url = config.uri + "admin/" + config.version + "/headings";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setHeadings(e.data);
      })
      .catch((err) => {
        setSnackbarMsg("Error in fetching headings");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const fetchImages = async () => {
    const url = config.uri + "admin/" + config.version + "/imageNameList";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setImages(e.data);
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const fetchTemplates = async () => {
    const url = config.uri + "admin/" + config.version + "/templates";
    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setCustomTemplates(e.data);
        for (let i = 0; i < e.data.length; i++) {
          setTemplates((prev) => [...prev, e.data[i].name]);
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    fetchHeadings();
    fetchImages();
    fetchTemplates();
  }, []);

  useEffect(() => {
    props.setHeader(() => (
      <Typography variant="h5" noWrap component="div">
        Data Sheets
      </Typography>
    ));
    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress style={{ color: "#1CA0C4" }} />
        </div>
      ) : (
        <div className={styles.main}>
          <div className={styles.headerRow}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flex: "1",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <FormControl
                sx={{
                  mb: 1,
                  mt: 1,
                  width: "600px",
                }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Category, Name, Code
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Search" edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Category, Name, Code"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FormControl>
            </div>
          </div>
          <table cellSpacing="0">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Product Type</th>
                <th>
                  <span>Product Category</span>

                  {sortType == "asc" && sortBy == "productCategory" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productCategory");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}

                  {!(sortType == "asc" && sortBy == "productCategory") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productCategory");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th>
                  <span>Product Name</span>
                  {sortType == "asc" && sortBy == "productName" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productName");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                  {!(sortType == "asc" && sortBy == "productName") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productName");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th>
                  <span>Product Code</span>
                  {sortType == "asc" && sortBy == "productCode" && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productCode");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortAsc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                  {!(sortType == "asc" && sortBy == "productCode") && (
                    <span>
                      <img
                        onClick={() => {
                          setSortBy("productCode");
                          setSortType((prevSortType) =>
                            prevSortType == "asc" ? "desc" : "asc"
                          );
                        }}
                        src={sortDesc}
                        style={{ cursor: "pointer" }}
                        width={"20px"}
                        height={"20px"}
                      />
                    </span>
                  )}
                </th>
                <th style={{ textAlign: "center" }}>Print</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((x, i) => {
                  let componentRef = React.createRef();

                  if (!validRow[i]) return;

                  return (
                    <tr key={`key${i}`}>
                      <td style={{ display: "none" }}>
                        {(rowTemplates[i] == "Template5" ||
                          x.template == "Template5") && (
                          <Template5
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}
                        {(rowTemplates[i] == "Template4" ||
                          x.template == "Template4") && (
                          <Template4
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}
                        {(rowTemplates[i] == "Template3" ||
                          x.template == "Template3") && (
                          <Template3
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}
                        {(rowTemplates[i] == "Template2" ||
                          x.template == "Template2") && (
                          <Template2
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}
                        {(rowTemplates[i] == "Template1" ||
                          x.template == "Template1") && (
                          <Template1
                            productName={x.productName}
                            productImage={
                              x.imageName ? x.imageName : rowImage[i]
                            }
                            headings={x.headings}
                            productDesc={x.desc}
                            text={x.text}
                            ref={(el) => (componentRef = el)}
                            company={props.company}
                          />
                        )}

                        {(() => {
                          for (let m = 0; m < customTemplates?.length; m++) {
                            if (
                              customTemplates[m].name == rowTemplates[i] ||
                              x.template == customTemplates[m].name
                            ) {
                              if (
                                customTemplates[m].derivedFrom == "Template1"
                              ) {
                                return (
                                  <Template1
                                    custom={true}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    customHeadings={customTemplates[m].headings}
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              if (
                                customTemplates[m].derivedFrom == "Template2"
                              ) {
                                return (
                                  <Template2
                                    custom={true}
                                    customHeadings={customTemplates[m].headings}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              if (
                                customTemplates[m].derivedFrom == "Template3"
                              ) {
                                return (
                                  <Template3
                                    custom={true}
                                    customHeadings={customTemplates[m].headings}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              if (
                                customTemplates[m].derivedFrom == "Template4"
                              ) {
                                return (
                                  <Template4
                                    custom={true}
                                    customHeadings={customTemplates[m].headings}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              if (
                                customTemplates[m].derivedFrom == "Template5"
                              ) {
                                return (
                                  <Template5
                                    custom={true}
                                    customHeadings={customTemplates[m].headings}
                                    productName={x.productName}
                                    productImage={
                                      x.imageName ? x.imageName : rowImage[i]
                                    }
                                    headings={x.headings}
                                    productDesc={x.desc}
                                    text={x.text}
                                    ref={(el) => (componentRef = el)}
                                    company={props.company}
                                  />
                                );
                              }
                              return null;
                            }
                          }
                        })()}
                      </td>
                      <td id="sno">{(currentPage - 1) * 25 + (i + 1)}</td>
                      <td>{x.productType}</td>
                      <td>{x.productCategory}</td>
                      <td>{x.productName}</td>
                      <td>{x.productCode}</td>

                      <td style={{ textAlign: "center" }}>
                        {validRow[i] && (
                          <ReactToPrint
                            trigger={() => (
                              <VisibilityOutlinedIcon
                                sx={{ cursor: "pointer", color: "#1ca0c4" }}
                              />
                            )}
                            documentTitle={x.productName}
                            content={() => componentRef}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className={styles.pagination}>
            <span>
              <ArrowBackIosIcon
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
                onClick={() => {
                  if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1);
                }}
              />
            </span>
            <b>{`${currentPage} of ${pages}`}</b>
            <span>
              <ArrowForwardIosIcon
                onClick={() => {
                  if (currentPage + 1 <= pages) setCurrentPage(currentPage + 1);
                }}
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
              />
            </span>
          </div>
        </div>
      )}
      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
    </div>
  );
}
