import React, { useState, useEffect } from "react";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";

import DeleteAlert from "../../components/DeleteAlert";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import config from "../../config";

import CustomLink from "../../components/CustomLink";
import SnackbarAlert from "../../components/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./images.module.css";

export default function Images(props) {
  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  }, []);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const [imgName, setImgName] = useState("");
  const [img, setImg] = useState(null);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  //for delete alert
  const [deleteItem, setDeleteItem] = useState(null);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  //For searching, sorting and pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const deleteIcon = require("../../Assets/deleteBlack.png");
  const addIcon = require("../../Assets/addUserIcon.png");
  const viewIcon = require("../../Assets/camera.png");

  useEffect(() => {
    props.setHeader(() =>
      props.roleLevel <= 2 ? (
        <div
          onClick={() => setOpenUploadDialog(true)}
          style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
        >
          <img
            src={addIcon}
            width="28px"
            height="28px"
            style={{ marginRight: "14px" }}
          />
          <Typography variant="h5" noWrap component="div">
            Add Image
          </Typography>
        </div>
      ) : (
        <Typography variant="h5" noWrap component="div">
          Images
        </Typography>
      )
    );
    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  const reset = () => {
    setImgName("");
    setImg("");
  };

  const fetchData = async () => {
    const url = config.uri + "admin/" + config.version + "/allImage";
    axios
      .get(url, {
        params: {
          company: props.company,
          for: "imagesPage",
          search: search,
          page: currentPage,
        },
      })
      .then((e) => {
        {
          setPages(Math.ceil(e.data?.total / 12));
          setData(e.data.images);
          setLoading(false);
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    fetchData();
  }, [search]);

  const handleDelete = (x) => {
    setDeleteItem(x);
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirmed = async () => {
    const name = deleteItem;

    const url = config.uri + "admin/" + config.version + "/deleteImage";

    await axios
      .get(url, { params: { name, company: props.company } })
      .then((e) => {
        if (e.data.message == "error") {
          setSnackbarMsg(e.data.reason);
          setSnackbarVariant("error");
          setSnackbarOpen(true);
        } else {
          setSnackbarMsg("success");
          setSnackbarVariant("success");
          setSnackbarOpen(true);
          setLoading(true);
          fetchData();
        }
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const handleFile = (e) => {
    let file = e.target.files[0];
    if (!file) return;

    document.getElementById("imageName").innerHTML = file.name;

    const size = file.size / 1024; // in KB

    if (size > 400) {
      setSnackbarMsg("Image size should be less than 400 KB");
      setSnackbarVariant("error");
      setSnackbarOpen(true);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const image = new Image();
      image.onload = function () {
        const width = this.width;
        const height = this.height;
        const aspectRatio = width / height;

        if (!(aspectRatio < 0.8 && aspectRatio > 0.65)) {
          setSnackbarMsg("Aspect ratio should be 3x4 (width x height)");
          setSnackbarVariant("warning");
          setSnackbarOpen(true);
          return;
        }
      };
      image.src = e.target.result;
    };

    reader.readAsDataURL(file);
    setImg(file);
  };

  const handleSubmit = async () => {
    if (!imgName || !img) return showError("image and image name");

    if (imgName.trim() == "") return showError("image name");

    setBtnLoading(true);

    const fileExtension = img.name.split(".").pop();
    const updatedImgName = imgName + "." + fileExtension;

    const formData = new FormData();
    formData.append("image", img, updatedImgName);
    formData.append("company", props.company);

    try {
      const url = config.uri + "admin/" + config.version + "/image";

      const response = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      reset();
      setSnackbarMsg("Upload Successful");
      setSnackbarVariant("success");
      setSnackbarOpen(true);
      fetchData();
    } catch (error) {
      setSnackbarMsg(error.message);
      setSnackbarVariant("error");
      setSnackbarOpen(true);
    } finally {
      setBtnLoading(false);
      setOpenUploadDialog(false);
    }
  };

  const showError = (reason) => {
    setSnackbarMsg("Error: Enter Valid " + reason);
    setSnackbarVariant("error");
    setSnackbarOpen(true);
    setLoading(false);
    return false;
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress style={{ color: "#1CA0C4" }} />
        </div>
      ) : (
        <div className={styles.section}>
          <div className={styles.headerRow}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flex: "1",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <FormControl
                sx={{
                  mb: 1,
                  mt: 1,
                  width: "600px",
                }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Search
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="Search" edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FormControl>
            </div>
          </div>
          <div className={styles.main}>
            {data &&
              data.map((x, i) => {
                return (
                  <Card
                    sx={{
                      display: "flex",
                      border: "1px solid #1ca0c4",
                      height: "220px",
                      width: "420px",
                      padding: "4px",
                    }}
                    key={x.name + i}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "280px !important",
                      }}
                    >
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography component="div" variant="h5">
                          {x.name.split("-").slice(0, -2)}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                        >
                          {"Full Name - " + x.name}
                        </Typography>
                      </CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          pl: 1,
                          pb: 1,
                          gap: "20px",
                        }}
                      >
                        <img
                          onClick={() =>
                            window.open(
                              `${config.uri}admin/${config.version}/getFile?company=${props.company}&key=${x.name}`
                            )
                          }
                          src={viewIcon}
                          width="28px"
                          height="28px"
                          style={{ cursor: "pointer" }}
                        />

                        {props.roleLevel < 2 && (
                          <img
                            onClick={() => handleDelete(x.name)}
                            src={deleteIcon}
                            width="28px"
                            height="28px"
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <CardMedia
                      component="img"
                      sx={{ width: 151 }}
                      src={`${config.uri}admin/${config.version}/getFile?company=${props.company}&key=${x.name}`}
                      alt="Image"
                    />
                  </Card>
                );
              })}
          </div>
          <div className={styles.pagination}>
            <span>
              <ArrowBackIosIcon
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
                onClick={() => {
                  if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1);
                }}
              />
            </span>
            <b>{`${currentPage} of ${pages}`}</b>
            <span>
              <ArrowForwardIosIcon
                onClick={() => {
                  if (currentPage + 1 <= pages) setCurrentPage(currentPage + 1);
                }}
                sx={{ cursor: "pointer", color: "#1ca0c4" }}
              />
            </span>
          </div>
        </div>
      )}

      {/* Upload Image Dialog */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
      >
        <DialogTitle
          sx={{
            fontSize: "25px",
            backgroundColor: "#1ca0c4",
            color: "white",
            marginBottom: "10px",
          }}
        >
          Upload Image
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ fontSize: "18px", marginTop: "10px" }}
            fullWidth
            label="Enter Image Name"
            onChange={(e) => setImgName(e.target.value)}
          >
            Image Name
          </TextField>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <input
              onChange={(e) => handleFile(e)}
              type="file"
              id="file-input"
              style={{ display: "none" }}
            />
            <label className={styles.customFileButton} for="file-input">
              Select Image
            </label>
            <span
              id="imageName"
              style={{ marginLeft: "20px", marginBottom: "10px" }}
            ></span>
          </div>
          <div>
            <Button
              sx={{
                backgroundColor: "#1ca0c4",
                color: "white",
                height: "50px",
                marginBottom: "10px",
                "&:hover": {
                  backgroundColor: "#147791",
                },
              }}
              onClick={handleSubmit}
            >
              {btnLoading ? (
                <CircularProgress
                  style={{ marginBottom: "-2px" }}
                  color="inherit"
                />
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              sx={{
                marginBottom: "10px",
                backgroundColor: "black",
                marginLeft: "20px",
                height: "50px",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
              color="error"
              onClick={() => setOpenUploadDialog(false)}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <DeleteAlert
        open={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        onDelete={handleDeleteConfirmed}
      />

      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
    </div>
  );
}
