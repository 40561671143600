import React, { useState, useEffect } from "react";

import styles from "./companyProfile.module.css";

import axios from "axios";
import config from "../../config";

import SnackbarAlert from "../../components/SnackbarAlert";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export default function CompanyProfile(props) {
  useEffect(() => {
    // Store the current page path in session storage
    sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  }, []);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("");

  const [companyPhone, setCompanyPhone] = useState("");
  const [companyAdd, setCompanyAdd] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [tax, setTax] = useState("");
  const [legalName, setLegalName] = useState("");

  const [noOfUsers, setNoOfUsers] = useState("0");
  const [disclaimer, setDisclaimer] = useState("");

  const [companyLogo, setCompanyLogo] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setCompanyLogo(file);
  };

  useEffect(() => {
    props.setHeader(() => (
      <div style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}>
        <Typography variant="h5" noWrap component="div">
          Company Profile
        </Typography>
      </div>
    ));
    props.setInfo(() => (
      <Typography style={{ maxWidth: "600px" }}></Typography>
    ));
  }, []);

  useEffect(() => {
    fetchData();
    fetchNoOfUsers();
  }, []);

  const fetchNoOfUsers = async () => {
    const url = config.uri + "public/" + config.version + "/noOfUsers";

    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setNoOfUsers(e.data?.noOfUsers);
      })
      .catch((err) => {
        setSnackbarMsg("Unable to get number of users");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const fetchData = async () => {
    const url = config.uri + "public/" + config.version + "/companyDetails";

    await axios
      .get(url, { params: { company: props.company } })
      .then((e) => {
        setData(e.data);
        setCompanyPhone(e.data?.CompanyPhone);
        setCompanyAdd(e.data?.CompanyAddress);
        setCompanyEmail(e.data?.CompanyEmail);
        setCompanyWebsite(e.data?.CompanyWebsite);
        setTax(e.data?.TAX_ID);
        setDisclaimer(e.data?.Disclaimer);
        setLegalName(e.data?.LegalName);

        const base64String = btoa(
          new Uint8Array(e.data?.Logo?.data?.data).reduce(function (
            data,
            byte
          ) {
            return data + String.fromCharCode(byte);
          },
          "")
        );

        setCompanyLogo(`data:image/png;base64,${base64String}`);
      })
      .catch((err) => {
        setSnackbarMsg(err.message);
        setSnackbarVariant("error");
        setSnackbarOpen(true);
      });
  };

  const handleServerUploadLogo = async () => {
    try {
      const formData = new FormData();
      formData.append("image", companyLogo, data.CompanyName);
      const url = config.uri + "public/" + config.version + "/uploadLogo";

      const response = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      setSnackbarMsg("Upload Successful");
      setSnackbarVariant("success");
      setSnackbarOpen(true);
      fetchData();
    } catch (error) {
      setSnackbarMsg(error.message);
      setSnackbarVariant("error");
      setSnackbarOpen(true);
    }
  };

  const handleCompanyDetailsChange = async () => {
    const params = {
      companyName: data.CompanyName,
      companyPhone: companyPhone,
      companyAdd: companyAdd,
      companyEmail: companyEmail,
      companyWebsite: companyWebsite,
      tax: tax,
      disclaimer: disclaimer,
      Country: data?.Country,
      Region: data?.Region,
      Industry: data?.Industry,
      VAT_ID: data?.VAT_ID,
      PAN: data?.PAN,
      GST: data?.GST,
      LegalName: legalName,
    };

    const url = config.uri + "public/" + config.version + "/newCompanyDetails";

    await axios
      .post(url, params)
      .then((e) => {
        setSnackbarMsg("Data updated successfully");
        setSnackbarVariant("success");
        setSnackbarOpen(true);
        fetchData();
      })
      .catch((err) => {
        setSnackbarMsg("Something went wrong try again later");
        setSnackbarVariant("error");
        setSnackbarOpen(true);
        return;
      });
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress style={{ color: "#1CA0C4" }} />
        </div>
      ) : (
        <div className={styles.main}>
          <div className={styles.header}>Company Information</div>
          <div className={styles.inputContainer}>
            <div className={styles.inputSection}>
              <span>
                <label className={styles.label} htmlFor="cNameInp">
                  Company Name
                </label>
                <input
                  value={data?.CompanyName}
                  className={styles.input}
                  id="cNameInp"
                  type="text"
                  name="Company Name"
                  readOnly={true}
                />
              </span>
              <span>
                <label className={styles.label} htmlFor="legalName">
                  Legal Name
                </label>
                <input
                  value={legalName}
                  onChange={(e) => setLegalName(e.target.value)}
                  className={styles.input}
                  id="legalName"
                  type="text"
                  name="Legal Name"
                />
              </span>
              <span>
                <label className={styles.label} htmlFor="cPhoneInp">
                  Company Phone
                </label>
                <input
                  className={styles.input}
                  id="cPhoneInp"
                  type="text"
                  name="Company Phone"
                  value={companyPhone}
                  onChange={(e) => setCompanyPhone(e.target.value)}
                />
              </span>
            </div>
            <div className={styles.inputSection}>
              <span>
                <label className={styles.label} htmlFor="cAddrInp">
                  Company Address
                </label>
                <textarea
                  rows={4}
                  cols={40}
                  className={styles.textarea}
                  id="cAddrInp"
                  type="text"
                  name="Company Address"
                  value={companyAdd}
                  onChange={(e) => setCompanyAdd(e.target.value)}
                />
              </span>
              <span>
                <label className={styles.label} htmlFor="cEmailInp">
                  Company Email
                </label>
                <input
                  className={styles.input}
                  id="cEmailInp"
                  type="text"
                  name="Company Email"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                />
              </span>
            </div>
          </div>
          <div className={styles.header}>Basic Information</div>
          <div className={styles.inputContainer}>
            <div className={styles.inputSection}>
              <span>
                <label className={styles.label} htmlFor="countryInp">
                  Country
                </label>
                <input
                  value={data?.Country}
                  onChange={(e) =>
                    setData({ ...data, Country: e.target.value })
                  }
                  className={styles.input}
                  id="countryInp"
                  type="text"
                  name="Country"
                />
              </span>
              <span>
                <label className={styles.label} htmlFor="indInp">
                  Industry
                </label>
                <input
                  value={data?.Industry}
                  className={styles.input}
                  onChange={(e) =>
                    setData({ ...data, Industry: e.target.value })
                  }
                  id="indInp"
                  type="text"
                  name="Industry"
                />
              </span>
            </div>
            <div className={styles.inputSection}>
              <span>
                <label className={styles.label} htmlFor="regInp">
                  Region
                </label>
                <input
                  value={data?.Region}
                  className={styles.input}
                  onChange={(e) => setData({ ...data, Region: e.target.value })}
                  id="regInp"
                  type="text"
                  name="Region"
                />
              </span>
              <span>
                <label className={styles.label} htmlFor="webInp">
                  Website
                </label>
                <input
                  className={styles.input}
                  value={companyWebsite}
                  id="webInp"
                  type="text"
                  name="Website"
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                />
              </span>
            </div>
          </div>
          <div className={styles.header}>Tax Information</div>
          <div className={styles.inputContainer}>
            <div className={styles.inputSection}>
              <span>
                <label className={styles.label} htmlFor="vatInp">
                  VAT Id
                </label>
                <input
                  value={data?.VAT_ID}
                  className={styles.input}
                  onChange={(e) => setData({ ...data, VAT_ID: e.target.value })}
                  id="vatInp"
                  type="text"
                  name="VAT"
                />
              </span>
              <span>
                <label className={styles.label} htmlFor="webInp">
                  PAN
                </label>
                <input
                  value={data?.PAN}
                  className={styles.input}
                  onChange={(e) => setData({ ...data, PAN: e.target.value })}
                  id="webInp"
                  type="text"
                  name="PAN"
                />
              </span>
            </div>
            <div className={styles.inputSection}>
              <span>
                <label className={styles.label} htmlFor="taxInp">
                  Tax Id
                </label>
                <input
                  className={styles.input}
                  value={tax}
                  id="taxInp"
                  type="text"
                  name="TAX"
                  onChange={(e) => setTax(e.target.value)}
                />
              </span>
              <span>
                <label className={styles.label} htmlFor="gstInp">
                  GST
                </label>
                <input
                  value={data?.GST}
                  onChange={(e) => setData({ ...data, GST: e.target.value })}
                  className={styles.input}
                  id="gstInp"
                  type="text"
                  name="GST"
                />
              </span>
            </div>
          </div>
          <div className={styles.header}>User Information</div>
          <div className={styles.inputContainer}>
            <div className={styles.inputSection}>
              <span>
                <label className={styles.label} htmlFor="usersInp">
                  No of Users
                </label>
                <input
                  readOnly={true}
                  className={styles.input}
                  value={noOfUsers}
                  id="usersInp"
                  type="text"
                  name="No. of Users"
                />
              </span>
              <span>
                <label className={styles.label} htmlFor="priEmailInp">
                  Primary User Email
                </label>
                <input
                  value={data?.PrimaryUserEmail}
                  className={styles.input}
                  readOnly={true}
                  id="priEmailInp"
                  type="text"
                  name="Primary User Email"
                />
              </span>
            </div>
            <div className={styles.inputSection}>
              <span>
                <label className={styles.label} htmlFor="priNameInp">
                  Primary User Name
                </label>
                <input
                  value={data?.PrimaryUserName}
                  className={styles.input}
                  readOnly={true}
                  id="priNameInp"
                  type="text"
                  name="Primary User Name"
                />
              </span>
              <span>
                <label className={styles.label} htmlFor="priPhoneInp">
                  Primary User Phone
                </label>
                <input
                  value={data?.PrimaryUserPhone}
                  className={styles.input}
                  readOnly={true}
                  id="priPhoneInp"
                  type="text"
                  name="Primary User Phone"
                />
              </span>
            </div>
          </div>
          <div className={styles.inputContainerFooter}>
            <div className={styles.inputSection}>
              <span>
                <label className={styles.label} htmlFor="discInp">
                  Disclaimer
                </label>
                <textarea
                  rows={6}
                  cols={40}
                  value={disclaimer}
                  onChange={(e) => setDisclaimer(e.target.value)}
                  className={styles.textarea}
                  id="discInp"
                  type="text"
                  name="Disclaimer"
                />
              </span>
            </div>
            <div className={styles.inputSection}>
              <span>
                <label className={styles.label} htmlFor="logoInp">
                  Company Logo
                </label>
                <div class="file-upload">
                  <label for="fileInput" className={styles.fileUploadButton}>
                    Upload Image
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </div>
                {companyLogo && (
                  <img
                    style={{ marginLeft: "-200px !important" }}
                    src={companyLogo}
                    width={"150px"}
                    height={"60px"}
                    alt="Selected"
                  />
                )}
              </span>
              <span className={styles.btnContainer}>
                <button onClick={handleServerUploadLogo}>Save Logo</button>
                <button onClick={handleCompanyDetailsChange}>
                  Save Other Fields
                </button>
              </span>
            </div>
          </div>
        </div>
      )}{" "}
      <SnackbarAlert
        openSnackbar={(e) => setSnackbarOpen(e)}
        variant={snackbarVariant}
        open={snackbarOpen}
        msg={snackbarMsg}
      />
    </div>
  );
}
